/**
 * This dig function safely access deeply nested object, or array structure
 * @param {*} path the path to access the object
 * @param {*} props the object to access
 * @returns {*} The value of the path. or null if any of the middle object does not exist
 */
const dig = <T>(
  path: (string | number)[],
  props: AnyObject | null
): T | null => {
  if (!Array.isArray(path)) return null;
  return path.reduce(
    (cumulate, cur) =>
      cumulate && cumulate[cur] !== undefined ? cumulate[cur] : undefined,
    props
  );
};
export default dig;
