import errorSubstring from './error_substring';
import pgErrors from './pg_errors';

const genericErrorTranslator = (err: Error) => {
  const allErrorTranslators = [pgErrors, errorSubstring];
  for (let i = 0; i < allErrorTranslators.length; i += 1) {
    const message = allErrorTranslators[i](err);
    if (message) return message;
  }
  return null;
};

export { errorSubstring, pgErrors, genericErrorTranslator };
