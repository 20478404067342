import CRUDFactory, { API_URL } from "./crud_factory";

type BillingPeriodNote = {
  id: number;
  periodableId: number;
};
type CreateBillingPeriodNoteResponse = BillingPeriodNote;
type UpdateBillingPeriodNoteResponse = BillingPeriodNote;
type DeleteBillingPeriodNoteResponse = BillingPeriodNote;
interface BillingPeriodNoteFactoryType extends CRUDFactory {
  create(params?: {}): Promise<CreateBillingPeriodNoteResponse>;
  update(params?: {}, id?: number | string): Promise<UpdateBillingPeriodNoteResponse>;
  delete(id: number | string): Promise<DeleteBillingPeriodNoteResponse>;
};
const BillingPeriodNoteFactory = new CRUDFactory({
  baseUrl: `${API_URL}billing_period_notes`,
}) as BillingPeriodNoteFactoryType;
export default BillingPeriodNoteFactory;