/**
 * @description module with wrappers around get and post requests with response and error handling
 */

import { DefaultHeaderProps } from 'SharedDirectory/utils/http_helpers';
import { json2param, fetchHandler, defaultHeader } from '../utils';

function logError(error: any) {
  return Promise.reject(error.message);
}

function translateFormDataRequest(
  params: BodyInit,
  options: DefaultHeaderProps
): [BodyInit, DefaultHeaderProps] {
  if (params instanceof FormData) {
    return [params, { ...(options || {}), csrf: true, formData: true }];
  }
  return [JSON.stringify(params), options];
}

/**
 * @description GET JSON request wrapper
 * @param path - passed in request url path, prefixing of any api versions should be included here
 * @param options - configure headers or handlers
 * @param params - object with keys - values that will be formatted to uri component as query params
 * @returns {Promise<Response>}
 */
export const getRequest = <T>(
  path: string,
  params?: BodyInit | any,
  options?: DefaultHeaderProps
) => {
  let url = path;
  if (params) {
    const paramString = json2param(params as AnyObject);
    url = `${path}?${paramString}`;
  }

  return fetch(url, {
    headers: defaultHeader(options),
    credentials: 'include',
  })
    .then((resp) => fetchHandler<T>(resp, options))
    .catch(logError);
};

/**
 * @description POST JSON + multipart/form-data request wrapper
 * @param path - passed in request url path, prefixing of any api versions should be included here
 * @param options - configure headers or handlers
 * @param params - object with keys - values for JSON body or FormData instance for multipart body
 * @returns {Promise<Response>}
 */
export const postRequest = <T>(
  path: string,
  params?: BodyInit | any,
  options?: DefaultHeaderProps
) => {
  const [newParams, newOptions] = translateFormDataRequest(params, options);

  return fetch(path, {
    method: 'POST',
    headers: defaultHeader(newOptions),
    credentials: 'include',
    body: newParams,
  })
    .then((resp) => fetchHandler<T>(resp, newOptions))
    .catch(logError);
};

/**
 * @description PUT JSON + multipart/form-data request wrapper
 * @param path - passed in request url path, prefixing of any api versions should be included here
 * @param options - configure headers or handlers
 * @param params - object with keys - values for JSON body or FormData instance for multipart body
 * @returns {Promise<Response>}
 */
export const putRequest = <T>(
  path: string,
  params: BodyInit | any,
  options?: DefaultHeaderProps
) => {
  const [newParams, newOptions] = translateFormDataRequest(params, options);

  return fetch(path, {
    method: 'PUT',
    headers: defaultHeader(newOptions),
    credentials: 'include',
    body: newParams,
  })
    .then((resp) => fetchHandler<T>(resp, newOptions))
    .catch(logError);
};

/**
 * @description PATCH JSON + multipart/form-data request wrapper
 * @param path - passed in request url path, prefixing of any api versions should be included here
 * @param options - configure headers or handlers
 * @param params - object with keys - values for JSON body or FormData instance for multipart body
 * @returns {Promise<Response>}
 */
export const patchRequest = <T>(
  path: string,
  params: BodyInit | any,
  options?: DefaultHeaderProps
) => {
  const [newParams, newOptions] = translateFormDataRequest(params, options);

  return fetch(path, {
    method: 'PATCH',
    headers: defaultHeader(newOptions),
    credentials: 'include',
    body: newParams,
  })
    .then((resp) => fetchHandler<T>(resp, newOptions))
    .catch(logError);
};

/**
 * @description DELETE request wrapper
 * @param path - passed in request url path, prefixing of any api versions should be included here
 * @param options - configure headers or handlers
 * @returns {Promise<Response>}
 */
export const deleteRequest = <T>(path: string, options?: DefaultHeaderProps) =>
  fetch(path, {
    method: 'DELETE',
    headers: defaultHeader(options),
    credentials: 'include',
  })
    .then((resp) => fetchHandler<T>(resp, options))
    .catch(logError);
