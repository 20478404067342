import camelCaseKeys from 'camelcase-keys';
import { notification } from 'antd';
import { fetchHandler } from '../utils';
import { postRequest } from '../api/client';
import CRUDFactory from './crud_factory';
import { defaultHeader, DefaultHeaderProps } from '../utils/http_helpers';

export type CurrentUserSimpleResponse = {
  data: User;
}
type CurrentUserResponse = CurrentUserSimpleResponse;
type User = {
  id: number;
  name: string;
  created_at: string;
  parentUser: User;
};
type ChangeUserResponse = {
  message: string;
}
export type GetUsersResponse = User[];
interface UserFactoryType extends CRUDFactory {
  get(options?: {}): Promise<GetUsersResponse>;
  getHunters(): Promise<GetUsersResponse>;
  getClosers(): Promise<GetUsersResponse>;
  getCurrentUserSimple(options?: {}): Promise<CurrentUserSimpleResponse['data']>;
  getCurrentUser(options?: {}): Promise<CurrentUserResponse['data']>;
  changeUser(userId: number): void;
}

class UserCRUDFactory extends CRUDFactory {
  currentUserUrl: string;

  getHunters() {
    return this.get({ department_id: 8 });
  }

  getClosers() {
    return this.get({ department_id: 7 });
  }

  getCurrentUserSimple(headerOptions: DefaultHeaderProps): Promise<CurrentUserSimpleResponse['data']> {
    const headers = headerOptions
      ? defaultHeader(headerOptions)
      : defaultHeader();
    this.currentUserUrl = '/react_home/current_user_simple/';
    return fetch(this.currentUserUrl, { headers, credentials: 'same-origin' })
      .then((result) => fetchHandler(result))
      .then((response: CurrentUserSimpleResponse) => {
        const { data = {} } = response;
        const camelData = camelCaseKeys(data, { deep: true });
        return camelData as CurrentUserSimpleResponse['data'];
      });
  }

  getCurrentUser(): Promise<CurrentUserResponse['data']> {
    this.currentUserUrl = '/react_home/get_current_user/';
    return fetch(this.currentUserUrl, {
      credentials: 'same-origin',
      headers: defaultHeader(),
    })
      .then((result) => fetchHandler(result))
      .then((response: CurrentUserResponse) => {
        const { data = {} } = response;
        const camelData = camelCaseKeys(data, { deep: true });
        return camelData as CurrentUserResponse['data'];
      });
  }

  // Switch users (dev / test / staging)
  changeUser(userId: number): void {
    postRequest('/users/change_user', {
      user_override_id: userId,
    }).then((data: ChangeUserResponse) => {
      if (data.message === 'Success') {
        // Refresh current user w/o cache
        this.getCurrentUserSimple({
          csrf: true,
          formData: false,
          noCache: true,
        })
          .then(() => {
            notification.success(data);
            // @ts-ignore
            window.location.reload(true);
          })
          .catch(() => {
            notification.success(data);
            // @ts-ignore
            window.location.reload(true);
          });
      } else {
        notification.error(data);
      }
    });
  }
}
const UserFactory = new UserCRUDFactory({ baseUrl: '/api/private/v4/users/' }) as UserFactoryType;
export default UserFactory;
