import snakeCaseKeys from 'snakecase-keys';
import CRUDFactory from './crud_factory';
import { postRequest } from '../api/client';

class HaulerPortalCRUDFactory extends CRUDFactory implements CRUDFactory {
  controllerUrl: string;

  constructor(props: any) {
    super(props);
  }

  create(data: { [name: string]: any }): Promise<Response> {
    const { haulerOrgId } = data;
    const params = { haulerPortal: data };
    return postRequest(
      `/hauler_orgs/${haulerOrgId}/hauler_portals`,
      snakeCaseKeys(params)
    );
  }
}

export default new HaulerPortalCRUDFactory({
  baseUrl: '/api/private/v4/hauler_portals/',
});
