import CRUDFactory, { API_URL } from './crud_factory';

type RecoveryFacilityPermit = {
  id: number;
  recoveryFacilityId: number;
  description: string;
  imagePreviewFile: string;
  image: string;
};
export type CreateRecoveryFacilityPermitResponse = RecoveryFacilityPermit;
export type UpdateRecoveryFacilityPermitResponse = RecoveryFacilityPermit;
interface RecoveryFacilityPermitFactoryType extends CRUDFactory {
  create(data: {}): Promise<CreateRecoveryFacilityPermitResponse>;
  update(data: {}, id?: number | string): Promise<UpdateRecoveryFacilityPermitResponse>;
};
export default new CRUDFactory({
  baseUrl: `${API_URL}recovery_facility_permits`,
}) as RecoveryFacilityPermitFactoryType;