import { __Field, __Type } from '@local_types';
import changeCase from 'change-case';
import pluralize from 'pluralize';

export type ColumnField = Pick<__Field, 'type'> & Partial<Omit<__Field, 'type'>>;

/**
 *
 * @param {String} name The table name
 * @returns {String} The Model Name
 */
export const toModelName = (name: string) => {
  const modelName = changeCase
    .titleCase(pluralize.singular(name))
    .replace(/ /, '');
  if (modelName === 'SnapshotBundle') {
    // Snapshot bundle is special, for users, it's proposal, but database has different name
    return 'Proposal';
  }
  return modelName;
};
/**
 *
 * @param {String} name  Model Name
 * @returns {String} table name
 */
export const toTableName = (name: string) =>
  changeCase.snakeCase(pluralize.plural(name));

/**
 * @descrption Get the type of a GraphqlField, Sometimes the type is wrapped undr NON_NULL
 * in which case we will need to go deep one more level
 * @param {GraphqlField} field
 * @returns {__Type} a type object.
 * @type {(field:
 * import('SharedDirectory/graphql_queries/__generated__/tableIntrospect').tableIntrospect___type_fields)=>
 * import('SharedDirectory/graphql_queries/__generated__/tableIntrospect').tableIntrospect___type_fields_type_ofType}
 */
export const getFieldType = (field: { type: __Type }) => {
  const { type } = field;
  return type.kind === 'NON_NULL' ? type.ofType : type;
};

/**
 * @description A filter function for GraphqlField, used in graphql table. We try
 * to hide default fields like "id", "createAt","updateAt", and depricated fields
 * like "xxxFileName" they are depricated paperclip fields, also hide it if it is object
 * because the table do not know how to display it.
 * @param {GraphqlField} field
 * @returns {Boolean} true means to keep it in the list.
 */
export const defaultFilterHiddenField = (field: ColumnField) => {
  const { name } = field;
  if (name.endsWith('Id') && !name.includes('By')) {
    // This is probably an foreign key, ignore it
    return false;
  }
  const info = getFieldType(field);
  const { kind } = info;
  if (kind === 'OBJECT') return false;

  // Also filter out the fields for attachment
  if (
    name.endsWith('ContentType') ||
    name.endsWith('FileSize') ||
    name.endsWith('UpdatedAt') ||
    name.endsWith('Url') ||
    name.endsWith('FileName')
  ) {
    // leave the _file_name, so that it is used in graphql form
    return false;
  }
  if (['createdAt', 'updatedAt'].includes(name)) {
    return false;
  }
  return true;
};

/**
 * @description A filter function for GraphqlField,  used in GraphqlForm. Similar to
 * defaultFilterHiddenField, we also do not show "id","createdAt","updatedAt", and the
 * deprecated fields. But we do keep the objects, because object tell us there is association
 * to be handled.
 * @see defaultFilterHiddenField
 * @param {GraphqlField} field
 */
export const filterScalarFieldsForForm = (field: ColumnField, includedFields: string[]) => {
  const { name } = field;
  if (includedFields && includedFields.includes(name)) return true;

  const info = getFieldType(field);
  const { kind } = info;
  if (kind !== 'SCALAR') return true;
  // Filter out the Id, location_id etc,
  if (name.endsWith('Id') && !name.includes('By')) {
    // This is probably an ID, ignore it
    return false;
  }
  // Deprecated fields from paperclip.
  if (
    name.endsWith('ContentType') ||
    name.endsWith('FileSize') ||
    name.endsWith('UpdatedAt') ||
    name.endsWith('Url') ||
    name.endsWith('FileName')
  ) {
    // remove the FileName fields, they are from paperclip,
    // but still allow the Filename, so that it is used in graphql form, it is to be distinguished with FileName
    return false;
  }
  if (['createdAt', 'updatedAt'].includes(name)) {
    return false;
  }
  return true;
};
/**
 * @description A function that filters through a list of GraphqlFields, This is a default
 * filter used in GraphqlTable, It also handles Strings, treating them as the name of GraphqlField
 * This function filters out the 1-1 and 1-many associations.
 * @param {Array<String|GraphqlField>} modelFields array of fields, either String or GraphqlFields
 * @param {object} options Describe to keep hasOne and hasMany associations.
 */
export const getAllColumns = (
  modelFields: ColumnField[],
  options: { hasOne?: boolean; hasMany?: boolean } = { hasOne: false, hasMany: false }
) => {
  const allColumns: ColumnField[] = [];
  const { hasOne = false, hasMany = false } = options;
  modelFields.forEach((f) => {
    if (typeof f === 'string') {
      allColumns.push(f);
    } else {
      const info = getFieldType(f);
      const { kind, name: typeName } = info;
      if (kind === 'SCALAR' || kind === 'LIST') {
        allColumns.push(f);
      } else if (kind === 'OBJECT') {
        if (
          typeName.endsWith('Connection') ||
          typeName.endsWith('Connection!')
        ) {
          // has many association
          if (hasMany) allColumns.push(f);
        } else if (hasOne) allColumns.push(f);
      }
    }
  });
  return allColumns;
};
export const isConnection = (typeName: string) =>
  typeName.endsWith('Connection') || typeName.endsWith('Connection!');

/**
 * @description Given a GraphqlField, generate the display name of the field. The name
 * of the graphqlField can be camelCase, and may follow the postGraphile fooByBarId pattern.
 * We try to convert that to more human readable name
 * @param {GraphqlField} field
 * @return {String} The display name
 */
export const getFieldDisplayName = (field: ColumnField) => {
  if (typeof field === 'string') {
    return field;
  }
  const { name } = field;
  const { kind, name: typeName } = getFieldType(field);
  if (kind === 'SCALAR') return changeCase.sentenceCase(name);
  if (typeName.includes('Connection')) {
    return pluralize.plural(changeCase.sentenceCase(name.split('By')[0]));
  }
  // It's a has_one association, name it as the foreinkey name
  if (name.includes('By')) {
    // Then it's regular foreign key
    return name.split('By')[1].replace(/Id/, '');
  }
  if (name.includes('As')) {
    // This is polymorphic association
    return changeCase.sentenceCase(name.split('As')[0]);
  }
  return name;
};
