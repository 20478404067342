import CRUDFactory, { Props, API_URL } from './crud_factory';
import * as client from '../api/client';

/**
 * @summary CRUD factory for stream types
 */
type StreamType = {
  id: number;
  name: string;
};
export type GetStreamTypesResponse = StreamType[];
export type GetByIdStreamTypesResponse = StreamType;
export type CreateStreamTypesResponse = StreamType;
export type UpdateStreamTypesResponse = StreamType;
export type DeleteStreamTypeResponse = StreamType;
interface StreamTypeFactoryType extends CRUDFactory {
  get(): Promise<GetStreamTypesResponse>;
  getById(id: number): Promise<GetByIdStreamTypesResponse>;
  create(params: {}, options?: { notify: boolean }): Promise<CreateStreamTypesResponse>;
  update(options: {}, id: number): Promise<UpdateStreamTypesResponse>;
  delete(id: number, options?: { notify: boolean }): Promise<DeleteStreamTypeResponse>;
}

class StreamTypeFactory extends CRUDFactory {
  controllerUrl: string;

  constructor(props: Props) {
    super(props);
    this.controllerUrl = '/stream_types';
  }

  update(params: {}, id: number, options = { notify: true }) {
    return client.putRequest(`${this.controllerUrl}/${id}`, params, options);
  }

  create(params: {}, options = { notify: true }) {
    return client.postRequest(this.controllerUrl, params, options);
  }

  get() {
    return client.getRequest(`${this.controllerUrl}/all_stream_types`) as Promise<GetStreamTypesResponse>;
  }
}

export default new StreamTypeFactory({
  baseUrl: `${API_URL}stream_types/`,
}) as unknown as StreamTypeFactoryType;
