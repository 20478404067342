import snakeCaseKeys from 'snakecase-keys';
import CRUDFactory from './crud_factory';
import { patchRequest, postRequest } from '../api/client';

interface CustomerReportDetailParams {
  startDate: string;
  endDate: string;
  locationIds: number[];
  useItemDate: boolean;
}

interface UpdatePdmSavingsParams {
  portfolioId: number;
  startDate: string;
  endDate: string;
}

class InvoiceCRUDFactory extends CRUDFactory {
  csvReportUrl = '/reporting/';
  updatePdmSavingsUrl = '/static_pages';
  constructor(props: any) {
    super(props);
  }
  customReportDetailCsv(params: CustomerReportDetailParams, options: any) {
    return postRequest(
      `${this.csvReportUrl}dump_customer_report_details`,
      params,
      options
    );
  }
  updatePdmSavings(params: UpdatePdmSavingsParams, options: any = {}) {
    return patchRequest(
      `${this.updatePdmSavingsUrl}/update_pdm_invoice_item_savings`,
      snakeCaseKeys(params)
    );
  }
}

export default new InvoiceCRUDFactory({
  baseUrl: '/api/private/v4/invoices/',
});
