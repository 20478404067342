import CRUDFactory, { API_URL } from "./crud_factory";

type ServicePattern = {
  id: number;
  pattern: string;
  frequency: number;
};
type GetServicePatternsResponse = ServicePattern[];
interface ServicePatternFactoryType extends CRUDFactory {
  get(params?: {}): Promise<GetServicePatternsResponse>;
};
const ServicePatternFactory = new CRUDFactory({
  baseUrl: `${API_URL}service_patterns/`,
}) as ServicePatternFactoryType;
export default ServicePatternFactory;