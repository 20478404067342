import gql from 'graphql-tag';
import TopSearchBar from '../presenters/top_search_bar';

export default gql`
  ${TopSearchBar.fragments.accountFields}
  ${TopSearchBar.fragments.locationFields}
  ${TopSearchBar.fragments.portfolioFields}
  query SearchPortfolios($search: String!) {
    response: allPortfolios(
      orderBy: []
      first: 10
      filter: { customerName: { includesInsensitive: $search } }
      customFilter: { orderByPosition: $search }
    ) {
      nodes {
        ...SearchPortfoliosFields
        accountsByPortfolioId {
          nodes {
            ...SearchAccountsFields
            locationsByAccountId {
              nodes {
                ...SearchLocationsFields
              }
            }
          }
        }
      }
    }
  }
`;
