import { shuffle } from 'lodash';

/**
 * @summary Generate a random color visible to the human eye
 */
const randomColor = () => {
  return shuffle([
    Math.floor(192 + Math.random() * 64),
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 224),
  ]);
};

export { randomColor };
