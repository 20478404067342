import CRUDFactory, { Props } from './crud_factory';

type Proposal = {
  id: number | string;
  created_at: string;
};
export type GetProposalsResponse = Proposal[];
interface ProposalCrudFactoryType extends CRUDFactory {
  link: string;
  get(options: {}): Promise<GetProposalsResponse>;
}
class ProposalCRUDFactory extends CRUDFactory {
  link: string;

  constructor(props: Props) {
    super(props);
    this.link = '/proposals';
  }
}
const ProposalFactory = new ProposalCRUDFactory({
  baseUrl: '/proposals/'
}) as ProposalCrudFactoryType;
export default ProposalFactory;
