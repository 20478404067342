import CRUDFactory, { API_URL } from './crud_factory';
import { postRequest } from '../api/client';

type RoutingPeriod = {
  id: number;
  startDate: string;
  endDate: string;
};
type GenerateRoutesResponse = {
  message: string;
};
type RunBeelineRoutingResponse = {
  message: string;
  status: string;
};
type GetRoutingPeriodsResponse = RoutingPeriod[];
interface RoutingPeriodFactoryType extends CRUDFactory {
  generateRoutes(id: number, beelineRoutingId: number | string): Promise<GenerateRoutesResponse>;
  runBeelineRouting(id: number, streamTypeId: number, rpsIds: number[], mrfIds: number[]): Promise<RunBeelineRoutingResponse>;
  get(params?: {}): Promise<GetRoutingPeriodsResponse>;
}
class RoutingPeriodFactory extends CRUDFactory {
  controllerUrl = '/routing_periods';
  constructor(props: any) {
    super(props);
  }

  generateRoutes(id: number, beelineRoutingId: number) {
    return postRequest(
      `
    ${this.controllerUrl}/${id}/generate_routes_from_beeline_result`,
      { beeline_routing_id: beelineRoutingId },
      { notify: true }
    );
  }

  runBeelineRouting(
    id: number,
    streamTypeId: number,
    rpsIds: number[],
    mrfIds: number[]
  ) {
    return postRequest(`${this.controllerUrl}/${id}/routing`, {
      stream_type: streamTypeId,
      rps_ids: rpsIds,
      mrf_ids: mrfIds,
    });
  }
}

export default new RoutingPeriodFactory({
  baseUrl: `${API_URL}routing_periods/`,
}) as RoutingPeriodFactoryType;
