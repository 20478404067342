import { fetchHandler, defaultHeader } from '../utils';

type SendEmailToCustomerResponse = {
  data: {
    status: boolean; message: string
  };
};

class EmailFactory {
  props: {};
  locationController: string;

  constructor(props?: {}) {
    this.props = props;
    this.locationController = '/locations';
  }

  sendEmailToCustomer(locationId: number, params: {}) {
    return fetch(
      `${this.locationController}/${locationId}/send_integration_email/`,
      {
        credentials: 'same-origin',
        headers: defaultHeader(),
        method: 'post',
        body: JSON.stringify(params),
      }
    ).then((result) => fetchHandler(result, { notify: true })) as Promise<SendEmailToCustomerResponse>;
  }
}

export default new EmailFactory();
