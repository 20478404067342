import CRUDFactory, { Props, API_URL } from './crud_factory';
import { deleteRequest } from '../api/client';

type DeleteWorkflowInstanceResponse = { success: boolean; message: string; };

function validateId(id: number | string) {
  if (Number.isNaN(parseInt(id as string, 10))) throw Error(`Unrecognized id: ${id}`);
}

class WorkflowInstanceFactory extends CRUDFactory {
  controllerUrl: string;

  constructor(props: Props) {
    super(props);
    this.controllerUrl = '/workflow_instances';
  }

  delete(id: number, options = { notify: true }) {
    validateId(id);
    return deleteRequest<DeleteWorkflowInstanceResponse>(`${this.controllerUrl}/${id}`, options);
  }
}

export default new WorkflowInstanceFactory({
  baseUrl: `${API_URL}workflow_instances/`,
});
