import * as React from 'react';
import { withApollo } from 'react-apollo';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';
import { compose, withHandlers, Omit } from 'recompose';
import TopSearchBar from '../presenters/top_search_bar';
import SearchLocationsQuery from '../queries/search_locations';
import SearchAccountsQuery from '../queries/search_accounts';
import SearchPortfoliosQuery from '../queries/search_portfolios';
import { useDebouncedCallback } from 'use-debounce';
import { SearchLocations, SearchAccounts, SearchPortfolios } from '@local_types';

try {
  // prevent from being imported twice
  require('core-js/stable'); // include for async + await
} catch (e) {
  // do nothing
}

const { useState } = React;

export interface SearchResponse {
  locations: ApolloQueryResult<SearchLocations>;
  accounts: ApolloQueryResult<SearchAccounts>;
  portfolios: ApolloQueryResult<SearchPortfolios>;
}

export interface MapResult {
  name: string;
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
}

export interface SearchResults {
  locations: SearchLocations['response'];
  accounts: SearchAccounts['response'];
  portfolios: SearchPortfolios['response'];
  mapResults: MapResult[];
}

export interface SearchByNameHandler {
  (search: string): SearchResponse;
}

export interface GeoSearchHandler {
  (search: string): MapResult[];
}

export interface Props {
  map?: boolean;
  service?: google.maps.places.PlacesService;
  onClick?: (value: string) => any;
  searchByName: SearchByNameHandler;
}

/**
 * @summary handle state for searching accounts and locations by name
 * and passing to a location search bar
 * @param inputVal - hold state of what is being typed
 * @param search - hold state of what to fetch as a name
 * @param selected - hold state of a fetched and selected name
 * @param error - error message
 * @param busy - hold fetching activity state
 * @param results - hold fetched search data
 */
const TopSearchBarContainer = (props: Props) => {
  const [inputVal, setInputVal] = useState('');
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState('');
  const [error, setError] = useState(undefined);
  const [busy, setBusy] = useState(false);
  const [results, setResults] = useState(undefined);

  // debounce change in search prompting a fetch by half a second
  const [searchDebounce] = useDebouncedCallback((search: string) => {
    setSearch(search);
    fetchSearch(search);
  }, 500);

  // call the fetch request handler and manage fetch related state
  const fetchSearch = async (val: string) => {
    setBusy(true);
    setError(undefined);

    const { locations, accounts, portfolios } = await props.searchByName(val);

    let errors: GraphQLError[] = [];
    if (locations.errors) errors = errors.concat(locations.errors);
    if (accounts.errors) errors = errors.concat(accounts.errors);
    if (portfolios.errors) errors = errors.concat(portfolios.errors);

    setBusy(false);
    setError(errors && errors[0]);

    const { map } = props;
    if (map) {
      const { service } = props;
      const request = {
        query: val,
        fields: ['name', 'geometry'],
      };
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setResults({
            locations: locations.data.response,
            accounts: accounts.data.response,
            portfolios: portfolios.data.response,
            mapResults: results,
          });
        }
      });
    } else {
      setResults({
        locations: locations.data.response,
        accounts: accounts.data.response,
        portfolios: portfolios.data.response,
      });
    }
  };

  // handle setting search value and resetting selected before fetch
  const onSearch = (inputValue: string) => {
    setInputVal(inputValue);
    setSelected('');
    const search = inputValue.trim();
    if (search.length >= 3) {
      searchDebounce(search);
    } else {
      setSearch(search);
      setResults(undefined);
    }
  };

  const valid = search.length >= 3; // under 3 characters skip query
  const resolveValue = selected.length > 0 ? selected : inputVal; // selected vs inputed value
  const { onClick } = props;

  return (
    <TopSearchBar
      search={search}
      loading={busy}
      error={error && error.message}
      results={results}
      valid={valid}
      value={resolveValue}
      onSearch={onSearch}
      onSelect={onClick}
    />
  );
};

type OuterProps = Omit<Props, 'searchByName'>;

export default compose<Props, OuterProps>(
  withApollo,
  withHandlers({
    searchByName:
      ({ client }) =>
        async (search: string) => {
          const locationsQuery = client.query({
            query: SearchLocationsQuery,
            errorPolicy: 'all',
            variables: { search },
          });

          const accountsQuery = client.query({
            query: SearchAccountsQuery,
            errorPolicy: 'all',
            variables: { search },
          });

          const portfoliosQuery = client.query({
            query: SearchPortfoliosQuery,
            errorPolicy: 'all',
            variables: { search },
          });

          return {
            locations: await locationsQuery,
            accounts: await accountsQuery,
            portfolios: await portfoliosQuery,
          };
        },
  })
)(TopSearchBarContainer);
