import CRUDFactory, { API_URL } from "./crud_factory";

type RecoveryFacility = {
  id: number;
  companyName: string;
};
type GetRecoveryFacilitiesResponse = RecoveryFacility[];
interface RecoveryFacilityFactoryType extends CRUDFactory {
  get(params?: {}): Promise<GetRecoveryFacilitiesResponse>;
};
const RecoveryFacilityFactory = new CRUDFactory({
  baseUrl: `${API_URL}recovery_facilities/`,
}) as RecoveryFacilityFactoryType;
export default RecoveryFacilityFactory;