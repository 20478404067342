import { createContext } from 'react';
import { ProviderProps } from 'react-redux';

type Props = React.PropsWithChildren<{ store: ProviderProps['store'] }>;
type Context = React.Context<unknown> & { ReduxProvider?: React.ComponentType<Props> };

// @ts-ignore
const ReduxContext: Context = createContext();

const ReduxProvider = (props: Props) => {
  const { store } = props;
  return (
    <ReduxContext.Provider value={{ store }}>
      {props.children}
    </ReduxContext.Provider>
  );
};

ReduxContext.ReduxProvider = ReduxProvider;
export default ReduxContext;
