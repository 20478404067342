import snakecaseKeys from 'snakecase-keys';
import { getRequest, postRequest } from '../api/client';
import { GetExistingExpenseSchedulesResponse } from './intacct_accounts_payable_factory';

class IntacctAccountsReceivableFactory {
  url = '/intacct_accounts_receivable';

  getIntacctExpenseSchedules(invoiceId: number) {
    return getRequest<GetExistingExpenseSchedulesResponse>(`${this.url}/find_expense_schedules?invoice_id=${invoiceId}`);
  }

  // flexible invoice push
  pushIntacctInvoiceNext(invoiceId: number) {
    return postRequest(
      `${this.url}/push_sales_invoice_next`,
      snakecaseKeys({ invoiceId }),
      { notify: true }
    );
  }
}

export default new IntacctAccountsReceivableFactory();
