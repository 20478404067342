/**
 * @description Shuffle an array, create random order
 * @param {Array} arr: Array of anything
 * @author Han Lai
 */

export const shuffle = <T>(arr: T[]) => {
  const array = [...arr];
  let currentIndex = array.length;
  let randomIndex = 0;
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    const temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

/**
 * @description Convert an Array of objects from database (an object with an id field) to json
 * @param {Array} array: An array of objects
 * @return {Object} JSON object where key is id, and value is object
 */
export interface ArrayToHashType {
  id: string | number;
}
export const arrayToHash = <T extends ArrayToHashType>(
  array: T[]
): { [x: string]: T } => {
  if (!Array.isArray(array)) return {};
  return array.reduce(
    (cumulative, current) => ({ ...cumulative, [current.id]: current }),
    {}
  );
};

/**
 * @description Compare two arrays. Only does shallow comparison of the values
 * @param {Array} a1
 * @param {Array} a2
 * @returns {Boolean} true if two arrays are the same
 */
export const compareArray = (a1: any[], a2: any[]) => {
  if (a1 === a2) return true;
  if (!Array.isArray(a1) || !Array.isArray(a2)) return false;
  if (a1.length !== a2.length) return false;
  a1.sort();
  a2.sort();
  let toReturn = true;
  a1.forEach((value, index) => {
    if (value !== a2[index]) toReturn = false;
  });
  return toReturn;
};

/**
 * @description Group by: Given array of objects, group by a key.
 * @param {string|function} key could be string that is accessable to all objects in array,
 *  or could be a function that generate a key given the object
 * @returns Js object with key => array of objects grouped by the key
 * @author Han Lai
 */
export type GroupByKeyFunc<T> = ((x: T) => string) | string;
export type GroupByResult<T> = { [x: string]: T[] };

export const groupBy = <T extends AnyObject>(
  data: T[],
  keyFunc: GroupByKeyFunc<T>
) =>
  data.reduce((cumulative, current) => {
    const key =
      typeof keyFunc === 'function' ? keyFunc(current) : current[keyFunc];
    const newCumulative: GroupByResult<T> = {
      ...cumulative,
      [key]: cumulative[key] || [],
    };
    newCumulative[key].push(current);
    return newCumulative;
  }, {} as GroupByResult<T>);

/**
 * remove all fields where value is undefined or null in object
 *
 */
export const compact = (jsonData: AnyObject) => {
  const toReturn: AnyObject = {};
  Object.entries(jsonData).forEach(([k, v]) => {
    if (v !== null && v !== undefined) {
      toReturn[k] = v;
    }
  });
  return toReturn;
};

/**
 * @summary Chunk an array into multiple arrays
 * @param inputArray
 * @param perChunk - items per chunk
 * @returns {*}
 */
export const chunkArray = <T = any>(inputArray: T[], perChunk: number) =>
  inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);
    const newResultArray = [...resultArray];

    if (!newResultArray[chunkIndex]) {
      newResultArray[chunkIndex] = []; // start a new chunk
    }

    newResultArray[chunkIndex].push(item);

    return newResultArray;
  }, [] as T[][]);

/**
 * @description Split an array based on a condition function, objects evaluated to true
 * will be in one array,
 * objects evaluated to false is in second array
 * @param {Array} inputArray
 * @param {Function} func the function evaluate the object
 * @returns {Array} An Array of 2 arrays
 */
export const splitArray = <T>(inputArray: T[], func: (x: T) => boolean) => {
  const toReturn: [T[], T[]] = [[], []];

  return inputArray.reduce((result, item) => {
    if (func(item)) {
      // If true, put it in good array
      result[0].push(item);
    } else {
      result[1].push(item);
    }
    return result;
  }, toReturn);
};

/**
 * A utility function to check if the supplied array does not exist, is not an array, or is empty.
 *
 * @param arr The array to inspect
 * @return {boolean} inspection result
 */
export const isEmpty = (arr: any[]) => !Array.isArray(arr) || !arr.length;

/**
 * A utility function to return the last element for which the provided function returns a
 * truthy value.
 * 
 * @param {Array} arr The array to search
 * @param {Function} fn The callback function for comparison
 * @returns {T} the element found or undefined
 */
export const findLast = <T>(arr: T[], fn: (x: T) => boolean) => {
  return arr.filter(fn).pop();
}

/**
 * A utility to get unique values of an array by the value's array
 * 
 * @param {Array} arr The array to get unique values by id 
 * @returns filtered array
 */
export const uniqueById = <T extends { id: number | string }>(arr: T[]): T[] => {
  return [
    ...new Map(arr.map((item) => [item.id, item])).values()
  ];
}