import CRUDFactory, { API_URL } from "./crud_factory";

type ForecastMeta = {
  id: number;
};
type ForecastMetaGetResponse = ForecastMeta[];
type ForecastMetaAddResponse = ForecastMeta;
type ForecastMetaUpdateResponse = ForecastMeta;
interface ForecastMetaFactoryType {
  get(options: {}): Promise<ForecastMetaGetResponse>;
  add(options: {}): Promise<ForecastMetaAddResponse>;
  update(options: {}, id: number): Promise<ForecastMetaUpdateResponse>;
}
const ForecastMetaFactory = new CRUDFactory({
  baseUrl: `${API_URL}forecast_meta/`,
}) as unknown as ForecastMetaFactoryType;

export default ForecastMetaFactory;