import * as React from 'react';
import ResponsiveAntMenu from 'responsive-ant-menu';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DownOutlined, GlobalOutlined } from '@ant-design/icons';
import { Layout, Menu, Dropdown, Button } from 'antd';
import Logo from 'SharedDirectory/images/svg_icons/rr-logo.svg';
import TopSearchBarContainer from 'SharedDirectory/components/top_search_bar/containers/top_search_bar_container';
const { Header } = Layout;
import 'antd/lib/layout/style';
import 'antd/lib/menu/style';
import 'antd/lib/col/style';
import 'antd/lib/row/style';
import 'antd/lib/dropdown/style';
import 'antd/lib/popover/style';
import './styles/override_foundation.scss';
import './styles/responsive.scss';
import useCurrentUser from 'SharedDirectory/hooks/use_current_user';
import { UserFactory } from '../../factories';

// Setup  type for window because we have ENV variable passed from rails
export interface WindowWithENV extends Window {
  RR_ENVIRONMENT: string;
}
declare let window: WindowWithENV;

/**
 * @description The React Nav bar on top of almost every page in coyote. This nav bar adopted the
 * original nav bar's functions. It also redirect to "map" page, "home" page, and allow searching
 * for locations and click to navigate to it. Right side is the menu for user actions, which
 * right now it only has a "sign out" function.
 * @requires UserContext The nav bar requires to be wrapped under UserContext, which is in shared
 * directory. It uses the context to get the current logged in user to display name, map url et.
 * @author Han Lai
 *
 */
export const TopBarController = () => {
  const { currentUser, status } = useCurrentUser();
  const { name, id } = currentUser;
  let backgroundColor = 'white';
  switch (window.RR_ENVIRONMENT) {
    case 'development': {
      backgroundColor = 'lightblue';
      break;
    }
    case 'staging': {
      backgroundColor = 'sandybrown';
      break;
    }
  }

  // The menu items for right side of nav bar
  const profileMenuItems = [
    <Menu.Item key='signout'>
      <a href='/signout'>Sign Out</a>
    </Menu.Item>,
  ];

  // Allow users to switch back to their original user from profile menu if applicable
  if (window.RR_ENVIRONMENT !== 'production' && currentUser.parentUser) {
    const { id, name } = currentUser.parentUser;
    profileMenuItems.unshift(
      <Menu.Item key='change_user'>
        <a onClick={() => UserFactory.changeUser(id)}>Switch back to {name}</a>
      </Menu.Item>
    );
  }

  const logo = <Logo style={{ width: '100%', height: '100%' }} />;
  const headLogo = (
    <a
      href='/'
      className='rr-main-logo'
      style={{ minWidth: 130, height: '100%' }}
    >
      {logo}
    </a>
  );

  // This right Menu uses ResponsiveAntMenu to manually control when to collapse into a button
  // The <Menu> does automatically collapse when the space is not ready, but the "automatic"
  // is a problem. In this case when the menu item has a logo, the width can be quite large,
  // (even though in the end the width is not that much), but at run time it still decide to
  // collapse.
  const rightMenu = (
    <ResponsiveAntMenu
      activeLinkKey={location.pathname}
      mobileBreakPoint={1000}
      mobileMenuContent={(isMenuShown: boolean) => {
        return (
          <Button type='primary'>
            <LegacyIcon type={isMenuShown ? 'menu-fold' : 'menu-unfold'} />
          </Button>
        );
      }}
      mode={(isMenuShown: boolean) => (isMenuShown ? 'vertical' : 'horizontal')}
      menuClassName={'responsive-ant-menu'}
    >
      {() => {
        return (
          <Menu
            mode='horizontal'
            style={{
              lineHeight: '48px',
              background: 'inherit',
              border: 'none',
            }}
          >
            <Menu.Item key='1' className='rr-menu-logo'>
              <a href='/' style={{ display: 'block', height: '100%' }}>
                {logo}
              </a>
            </Menu.Item>
            <Menu.Item key='2'>
              <a href='/map'>
                <GlobalOutlined /> Map
              </a>
            </Menu.Item>
            <Menu.Item key='3'>
              {id && (
                <Dropdown overlay={<Menu>{profileMenuItems}</Menu>}>
                  <a href='#'>
                    {`${name || ''} `}
                    <DownOutlined />
                  </a>
                </Dropdown>
              )}
              {!id && status !== 'loading' && 'Not Logged In'}
            </Menu.Item>
          </Menu>
        );
      }}
    </ResponsiveAntMenu>
  );
  return (
    <TopBarPresenter
      logo={headLogo}
      rightMenu={rightMenu}
      className='rr-main-nav-bar no-print'
      style={{ backgroundColor }}
    >
      <div style={{ maxWidth: 500, width: '100%' }}>
        {id && <TopSearchBarContainer />}
      </div>
    </TopBarPresenter>
  );
};

export interface TopBarPresenterProps {
  logo: React.ReactNode;
  rightMenu: React.ReactNode;
  children: React.ReactNode | React.ReactNodeArray;
  style?: React.CSSProperties;
  className?: string;
}
export const TopBarPresenter: React.SFC<TopBarPresenterProps> = (props) => {
  const { logo, rightMenu, style = {}, children, className = '' } = props;
  return (
    <Layout className={className}>
      <Header
        style={{
          height: 'auto',
          lineHeight: '48px',
          background: 'white',
          borderBottom: '1px solid #e8e8e8',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          ...style,
        }}
      >
        <div style={{ height: 48, paddingLeft: 5, paddingRight: 5 }}>
          {logo}
        </div>
        <div
          style={{
            width: '100%',
            // This is important, this makes the right menu don't collapse, if it try to take as much
            // as it can, the right menu bar will cave, and collapse into a button
            maxWidth: 700,
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          {children}
        </div>

        <div style={{ paddingLeft: 5, paddingRight: 5 }}>{rightMenu}</div>
      </Header>
    </Layout>
  );
};
