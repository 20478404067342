import CRUDFactory, { Props } from './crud_factory';
import { fetchHandler, json2param } from '../utils';

export type GetAccountsByNameResponse = {
  account: string;
  id: number;
  locations: {
    id: number;
  }[];
  portfolio: {
    id: number;
  };
}[];
type Account = {
  id: number;
  name: string;
};
export type GetAccountWithLoaResponse = (Account & {
  locations: {
    id: number;
    name: string;
    target_integration_date: string;
    display_name: string;
    haulers_with_account: {
      id: number;
      checked: boolean;
      display_name: string;
    }[];
    loa_agreements: {
      id: number;
      hauler_id: number;
    }[];
  }[];
})[]
export type GetAccountsResponse = Account[];
interface AccountCrudFactoryType extends CRUDFactory {
  getAccountWithLoa(options: {}): Promise<GetAccountWithLoaResponse>;
  getAccountsByName(options: {}): Promise<GetAccountsByNameResponse>;
  get(options: {}): Promise<GetAccountsResponse>;
}
class AccountCRUDFactory extends CRUDFactory {
  searchUrl: string;
  controllerUrl: string;

  constructor(props: Props) {
    super(props);
    this.searchUrl = '/maps/filter_locations?';
    this.controllerUrl = '/accounts/';
  }

  getAccountWithLoa(options: {}) {
    const paramString = json2param(options);
    return fetch(`${this.controllerUrl}location_loa_status/?${paramString}`, {
      credentials: 'same-origin',
    }).then(fetchHandler);
  }

  getAccountsByName(options: {}) {
    const paramString = json2param(options);
    return fetch(`${this.searchUrl}${paramString}`, {
      credentials: 'same-origin',
    }).then(fetchHandler);
  }
}

export default new AccountCRUDFactory({
  baseUrl: '/api/private/v4/accounts/'
}) as AccountCrudFactoryType;
