import CRUDFactory, { API_URL } from './crud_factory';

type MarketPricing = {
  id: number;
  removalType: string;
  regionName: string;
  price: number;
};
export type UpdateMarketPricingResponse = MarketPricing;
export type GetMarketPricingResponse = MarketPricing[];
interface MarketPricingFactoryType extends CRUDFactory {
  get(options: {}): Promise<GetMarketPricingResponse>;
  update(options: {}, id: number | string): Promise<UpdateMarketPricingResponse>;
};
export default new CRUDFactory({
  baseUrl: `${API_URL}market_pricings/`,
}) as MarketPricingFactoryType;