import CRUDFactory, { API_URL } from './crud_factory';
import { postRequest } from '../api/client';

type RoutingPeriodService = {
  id: number;
  state: string;
  city: string;
  zip: string;
  name: string;
};
type UpdateSelectionResponse = {
  message: string;
};
type GetRoutingPeriodServicesResponse = RoutingPeriodService[];
interface RoutingPeriodServiceFactoryType extends CRUDFactory {
  updateSelection(id: number[], isSelected: boolean, options?: {}): Promise<UpdateSelectionResponse>;
  get(params?: {}): Promise<GetRoutingPeriodServicesResponse>;
};
class RoutingPeriodServiceFactory extends CRUDFactory {
  controllerUrl = '/routing_period_services';
  constructor(props: any) {
    super(props);
  }
  updateSelection(id: number[], isSelected: boolean, options: any = {}) {
    return postRequest(
      `
    ${this.controllerUrl}/update_selection`,
      {
        id,
        is_selected: isSelected,
      },
      options
    );
  }
}

export default new RoutingPeriodServiceFactory({
  baseUrl: `${API_URL}routing_period_services/`,
}) as RoutingPeriodServiceFactoryType;
