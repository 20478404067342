import styled from 'styled-components';

type FontProps<T extends string> = { [U in T]?: boolean };
type FontSizes = 'large' | 'small' | 'tiny';
type FontWeights = 'bold' | 'light';
type FontStyles = 'italic';
type TextDecoration = 'underline';
type TextAlignment = 'right' | 'center';
type FontColoring = { color?: string; mood?: 'alert' | 'caution' | 'valid' };
type Props = FontProps<FontSizes> &
  FontProps<FontWeights> &
  FontProps<FontStyles> &
  FontProps<TextAlignment> &
  FontProps<TextDecoration> &
  FontColoring;

const Text = styled.div<Props>`
  display: inline;
  font-size: ${({ large, small, tiny }) => {
    if (large) return '1.1em';
    if (small) return '0.8em';
    if (tiny) return '0.65em';
    return '1em';
  }};
  font-weight: ${({ bold, light }) => {
    if (bold) return 600;
    if (light) return 300;
    return 400;
  }};
  font-style: ${({ italic }) => {
    if (italic) return 'italic';
    return 'normal';
  }};
  text-decoration: ${({ underline }) => {
    if (underline) return 'underline';
    return 'none';
  }};
  text-align: ${({ right, center }) => {
    if (right) return 'right';
    if (center) return 'center';
    return 'left';
  }};
  color: ${({ color, mood }) => {
    if (mood === 'alert') {
      return '#CC3300';
    }
    if (mood === 'valid') {
      return '#339900';
    }
    if (mood === 'caution') {
      return '#FF9966';
    }
    if (color) return color;
    return 'rgba(0, 0, 0, 0.65)';
  }};
`;

export default Text;
