const dayMap: Record<string, string> = {
  Sunday: 'Su',
  Sun: 'Su',
  Su: 'Su',
  Monday: 'Mo',
  Mon: 'Mo',
  Mo: 'Mo',
  Tuesday: 'Tu',
  Tue: 'Tu',
  Tu: 'Tu',
  Wednesday: 'We',
  Wed: 'We',
  We: 'We',
  Thursday: 'Th',
  Thurs: 'Th',
  Thur: 'Th',
  Thu: 'Th',
  Th: 'Th',
  Friday: 'Fr',
  Fri: 'Fr',
  Fr: 'Fr',
  Saturday: 'Sa',
  Sat: 'Sa',
  Sa: 'Sa',
};

export const toWeekdayAbbr = (weekday: string) => dayMap[weekday];
