import CRUDFactory, { API_URL } from './crud_factory';

type Hauler = {
  id: number;
  displayName: string;
};
type GetHaulersResponse = Hauler[];
interface HaulerFactoryType extends CRUDFactory {
  get(payload: {}): Promise<GetHaulersResponse>;
};
export default new CRUDFactory({
  baseUrl: `${API_URL}haulers/`
}) as HaulerFactoryType;