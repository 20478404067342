import { fetchHandler, defaultHeader } from '../utils';
import CRUDFactory from './crud_factory';

type BeelineSimulation = {
  id: number;
  input: string;
  output: string;
  status: string;
  newRecord: boolean;
  streamTypeId: number;
  fleetHaulRegionId: number;
  updatedAt: string;
  title: string;
  simulationRequestId: number;
}
export type SimulateRoutingPeriodResponse = BeelineSimulation;
export type SimulationStopResponse = BeelineSimulation;
export type GetSimulationResponse = BeelineSimulation;
interface BeelineSimulationFactoryType extends CRUDFactory {
  simulation(data: {}): Promise<SimulateRoutingPeriodResponse>;
  stopSimulation(id: number): Promise<SimulationStopResponse>;
  get(params?: {}): Promise<GetSimulationResponse>;
}
class BeelineSimulationFactory extends CRUDFactory {
  simulation(data: {}) {
    return fetch(`${this.baseUrl}/simulate_routing_period`, {
      credentials: 'same-origin',
      headers: defaultHeader(),
      method: 'post',
      body: JSON.stringify({ data }),
    }).then((result) => fetchHandler(result, { notify: true }));
  }

  stopSimulation(id: number) {
    return fetch(`${this.baseUrl}/${id}/simulation_stop`, {
      credentials: 'same-origin',
      headers: defaultHeader(),
      method: 'post',
    }).then((result) => fetchHandler(result, { notify: true }));
  }
}

export default new BeelineSimulationFactory({
  baseUrl: '/beeline_simulations/',
}) as BeelineSimulationFactoryType;
