import * as React from 'react';
import gql from 'graphql-tag';
import { Text } from 'SharedDirectory/styles/components';
import {
  EnvironmentOutlined,
  HomeOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Input, AutoComplete, Spin } from 'antd';
import IntegrationStatusLabel from './integration_status_label';
import { SearchResults } from '../containers/top_search_bar_container';
import Highlighter from '../../highlighter';
import memoizeOne from 'memoize-one';
import 'antd/lib/auto-complete/style';
import 'antd/lib/input/style';
import 'antd/lib/spin/style';
import { SearchPortfolios } from '@local_types';

/**
 * @summary list view for searched accounts and locations
 */

const fragments = {
  locationFields: gql`
    fragment SearchLocationsFields on Location {
      id
      displayName
      salesStatus
      opsStatus
    }
  `,
  accountFields: gql`
    fragment SearchAccountsFields on Account {
      id
      name
    }
  `,
  portfolioFields: gql`
    fragment SearchPortfoliosFields on Portfolio {
      id
      customerName
    }
  `,
};

interface Props {
  search: string;
  onSearch: (value: string) => any;
  onSelect?: (value: string) => any;
  valid: boolean;
  loading: boolean;
  error: string;
  value: string;
  results: SearchResults;
}

interface State {
  open?: boolean;
}

/**
 * @summary Score a single result for sorting
 * @param obj
 * @param key
 * @param search
 */
const scoreResult = (
  obj: { [index: string]: any },
  key: string,
  search: string
) => {
  const name = obj[key].trim().toLowerCase();
  const nameIndex = name.indexOf(search);
  if (nameIndex !== -1) {
    return 1 / (nameIndex + 1);
  }
  return null;
};

/**
 * @summary Score all search results based on portfolio, account, location
 * @param portfolios
 * @param search
 */
const scoreResults = (
  portfolios: SearchPortfolios['response']['nodes'],
  search: string
) => {
  const portfolioScores: { [index: string]: number } = {};
  const searchLowerCase = search.trim().toLowerCase();

  portfolios.forEach((portfolio) => {
    let score = scoreResult(portfolio, 'customerName', searchLowerCase);
    if (score) {
      portfolioScores[portfolio.id] = 5 + score;
    } else {
      const accounts = portfolio.accountsByPortfolioId.nodes;

      if (accounts.length > 0) {
        const accountScores = accounts.map((account) =>
          scoreResult(account, 'name', searchLowerCase)
        );
        score = Math.max(...accountScores);
        if (score) {
          portfolioScores[portfolio.id] = 3 + score;
        } else {
          let locations: any = [];
          accounts.forEach((account) => {
            locations = locations.concat(account.locationsByAccountId.nodes);
          });

          const locationScores = locations.map((location: any) =>
            scoreResult(location, 'displayName', searchLowerCase)
          );

          if (locations.length > 0) {
            score = Math.max(...locationScores);
            portfolioScores[portfolio.id] = 1 + score;
          } else {
            portfolioScores[portfolio.id] = 0;
          }
        }
      } else {
        portfolioScores[portfolio.id] = 0;
      }
    }
  });

  return portfolioScores;
};

/**
 * @summary Sort a single level of the portfolio, account, location, hierarchy
 * @param name
 * @param search
 * @param flag
 */
const sortOnSearch =
  (name: string, search: string, flag?: 'portfolio' | null | undefined) =>
    (a: any, b: any) => {
      const searchLowerCase = search.trim().toLowerCase();

      const aName = a[name].trim().toLowerCase();
      const bName = b[name].trim().toLowerCase();

      let aIndex = aName.indexOf(searchLowerCase);
      let bIndex = bName.indexOf(searchLowerCase);

      // Not finding the index scores lowest
      aIndex = aIndex === -1 ? 99999 : aIndex;
      bIndex = bIndex === -1 ? 99999 : bIndex;

      const comparison = aIndex - bIndex;

      // Equal weightings, sort alphabetically
      if (comparison === 0) {
        return aName.trim().localeCompare(bName.trim());
      }

      return aIndex - bIndex;
    };

const parseResultsToPortfolios = (
  results: SearchResults,
  search: string
): SearchPortfolios['response']['nodes'] => {
  const { portfolios, locations, accounts } = results;

  const portfolioNodes = [...portfolios.nodes];
  const accountNodes = [...accounts.nodes];
  const locationNodes = [...locations.nodes];

  const portfolioIds = portfolioNodes.map((p) => p.id);
  const filteredAccountNodes = accountNodes.filter((a) => {
    const { portfolioByPortfolioId: p } = a;
    if (p) {
      if (!portfolioIds.includes(p.id)) {
        portfolioIds.push(p.id);
        return true;
      }
      return false;
    }
    return true;
  });

  const accountIds = filteredAccountNodes.map((a) => a.id);
  const filteredLocationNodes = locationNodes.filter((l) => {
    const { accountByAccountId: a } = l;
    if (a) {
      const { portfolioByPortfolioId: p } = a;
      if (p) {
        if (!portfolioIds.includes(p.id)) {
          portfolioIds.push(p.id);
          return true;
        }
        return false;
      }

      if (!accountIds.includes(a.id)) {
        accountIds.push(a.id);
        return true;
      }
      return false;
    }
    return true;
  });

  const noPortfolio: SearchPortfolios['response']['nodes'][number] = {
    id: '-1',
    customerName: 'No Portfolio',
    accountsByPortfolioId: {
      nodes: [],
      __typename: 'AccountsConnection',
    },
    __typename: 'Portfolio',
  };

  filteredAccountNodes.forEach((a) => {
    const { portfolioByPortfolioId: p } = a;
    if (p) {
      portfolioNodes.push({
        ...p,
        accountsByPortfolioId: {
          nodes: [{ ...a }],
          __typename: 'AccountsConnection',
        },
      });
    } else {
      noPortfolio.accountsByPortfolioId.nodes.push(a);
    }
  });

  filteredLocationNodes.forEach((l) => {
    const { accountByAccountId: a } = l;
    const { portfolioByPortfolioId: p } = a;

    if (p) {
      portfolioNodes.push({
        ...p,
        accountsByPortfolioId: {
          nodes: [
            {
              ...a,
              locationsByAccountId: {
                nodes: [{ ...l }],
                __typename: 'LocationsConnection',
              },
            },
          ],
          __typename: 'AccountsConnection',
        },
      });
    } else {
      noPortfolio.accountsByPortfolioId.nodes.push({
        ...a,
        locationsByAccountId: {
          nodes: [{ ...l }],
          __typename: 'LocationsConnection',
        },
      });
    }
  });

  const filteredAccountIds = filteredAccountNodes.map((a) => a.id);
  const remainingAccountNodes = accountNodes.filter(
    (a) => !filteredAccountIds.includes(a.id)
  );
  if (remainingAccountNodes.length > 0) {
    remainingAccountNodes.forEach((a) => {
      if (a) {
        const { portfolioByPortfolioId: p } = a;
        if (p) {
          const portfolio = portfolioNodes.find((pn) => pn.id === p.id);
          if (
            portfolio &&
            !portfolio.accountsByPortfolioId.nodes.some((pa) => pa.id === a.id)
          ) {
            portfolio.accountsByPortfolioId.nodes.push(a);
          }
        }
      }
    });
  }

  const filteredLocationIds = filteredLocationNodes.map((a) => a.id);
  const remainingLocationNodes = locationNodes.filter(
    (l) => !filteredLocationIds.includes(l.id)
  );
  if (remainingLocationNodes.length > 0) {
    remainingLocationNodes.forEach((l) => {
      const { accountByAccountId: a } = l;
      if (a) {
        const { portfolioByPortfolioId: p } = a;
        if (p) {
          const portfolio = portfolioNodes.find((pn) => pn.id === p.id);
          const account = portfolio.accountsByPortfolioId.nodes.find(
            (an) => an.id === a.id
          );
          if (
            account &&
            !account.locationsByAccountId.nodes.some((al) => al.id === l.id)
          ) {
            account.locationsByAccountId.nodes.push(l);
          } else if (portfolio && !account) {
            portfolio.accountsByPortfolioId.nodes.push({
              ...a,
              locationsByAccountId: {
                nodes: [{ ...l }],
                __typename: 'LocationsConnection',
              },
            });
          }
        }
      }
    });
  }

  if (noPortfolio.accountsByPortfolioId.nodes.length > 0) {
    portfolioNodes.push(noPortfolio);
  }

  if (portfolioNodes.length > 1) {
    const portfolioScores = scoreResults(portfolioNodes, search);
    portfolioNodes.sort((a, b) => {
      const aScore = portfolioScores[a.id] || 0;
      const bScore = portfolioScores[b.id] || 0;

      if (aScore === bScore) {
        const aName = a.customerName.trim().toLowerCase();
        const bName = b.customerName.trim().toLowerCase();
        return aName.localeCompare(bName);
      }

      return bScore - aScore;
    });
  }

  return portfolioNodes;
};

interface OptionData {
  label: JSX.Element | string;
  value: string;
  key: string;
  disabled?: boolean;
  title?: string;
}
/**
 * @summary Format options / search results for auto-complete
 */
const formatOptions = memoizeOne(
  ({ valid, loading, error, results, search }: Props) => {
    let returnValue: OptionData[] = [
      {
        label: 'Type at least 3 characters...',
        value: '',
        disabled: true,
        key: '0_characters',
      },
    ];

    if (valid) {
      if (loading) {
        returnValue = [
          {
            label: <Spin />,
            value: '',
            disabled: true,
            key: '0_loading',
          },
        ];
      } else if (error) {
        returnValue = [
          {
            label: (
              <Text color='red'>
                A problem occurred while searching: {error}
              </Text>
            ),
            value: '',
            disabled: true,
            key: '0_error',
          },
        ];
      } else if (results) {
        const portfolios = parseResultsToPortfolios(results, search);
        const { mapResults } = results;
        const options: OptionData[] = [];
        if (mapResults && mapResults.length > 0) {
          mapResults.forEach((mapResult) => {
            const { geometry, name } = mapResult;
            options.push({
              label: (
                <span>
                  <EnvironmentOutlined style={{ marginRight: 10 }} />
                  <span style={{ marginRight: 5 }}>
                    <Highlighter text={name} searchText={search} />
                  </span>
                </span>
              ),
              key: `${geometry.location.lat()}+${geometry.location.lng()}+${name}`,
              value: `${geometry.location.lat()}+${geometry.location.lng()}+${name}`,
              title: name,
            });
          });
        } else if (portfolios.length === 0) {
          // no results
          options.push({
            label: 'No Results',
            value: '',
            disabled: true,
            key: '0',
          });
        }
        portfolios.forEach((portfolio) => {
          const {
            id: portfolioId,
            accountsByPortfolioId,
            customerName: portfolioName,
          } = portfolio;

          options.push({
            label: (
              <span>
                <UserOutlined style={{ marginRight: 10 }} />
                <span style={{ marginRight: 5 }}>
                  <Highlighter text={portfolioName} searchText={search} />
                </span>
              </span>
            ),
            value: `/portfolios/${portfolioId}`,
            key: `${portfolioId}_portfolio`,
            title: portfolioName,
          });

          const accountNodes = [...accountsByPortfolioId.nodes];
          if (accountNodes.length > 1) {
            accountNodes.sort(sortOnSearch('name', search));
          }

          accountNodes.forEach((account) => {
            const {
              id: accountId,
              name: accountName,
              locationsByAccountId,
            } = account;

            options.push({
              label: (
                <span style={{ marginLeft: 10 }}>
                  <HomeOutlined style={{ marginRight: 10 }} />
                  <span style={{ marginRight: 5 }}>
                    <Highlighter text={accountName} searchText={search} />
                  </span>
                </span>
              ),
              key: `${accountId}_account`,
              value: `/accounts/${accountId}`,
              title: accountName,
            });

            const locationNodes = [...locationsByAccountId.nodes];
            if (locationNodes.length > 1) {
              locationNodes.sort(sortOnSearch('displayName', search));
            }

            locationNodes.forEach((location) => {
              const {
                id: locationId,
                displayName: locationName,
                salesStatus,
                opsStatus,
              } = location;

              options.push({
                label: (
                  <React.Fragment>
                    <span style={{ marginLeft: 20 }}>
                      <EnvironmentOutlined style={{ marginRight: 10 }} />
                      <span style={{ marginRight: 5 }}>
                        <Highlighter text={locationName} searchText={search} />
                      </span>
                    </span>
                    <span style={{ float: 'right' }}>
                      <IntegrationStatusLabel
                        salesStatus={salesStatus}
                        opsStatus={opsStatus}
                      />
                    </span>
                  </React.Fragment>
                ),
                key: `${locationId}_location`,
                value: `/locations/${locationId}`,
                title: locationName,
              });
            });
          });
        });

        returnValue = options;
      }
    }
    return returnValue;
  }
);

/**
 * @description Render search bar for finding locations
 */
class TopSearchBar extends React.Component<Props, State> {
  static fragments = fragments;

  state = { open: false };

  openSelect = () => {
    const { open } = this.state;
    if (!open) this.setState({ open: true });
  };

  closeSelect = () => {
    const { open } = this.state;
    if (open) this.setState({ open: false });
  };

  onSearch = (value: string) => {
    const { onSearch } = this.props;
    this.openSelect();
    onSearch(value);
  };

  onSelect = (value: string) => {
    const { onSelect } = this.props;
    this.openSelect();
    if (onSelect) {
      onSelect(value);
    } else if (value !== '/portfolios/-1') {
      // no portfolio link
      // @ts-ignore
      if (event.ctrlKey || event.metaKey) {
        window.open(value, '_blank');
      } else {
        window.location.href = value;
      }
    }
  };

  render() {
    const { value } = this.props;
    const { open } = this.state;
    const options = formatOptions({ ...this.props });

    return (
      <AutoComplete
        size='large'
        style={{ width: '100%' }}
        open={open}
        onFocus={this.openSelect}
        onBlur={this.closeSelect}
        onSearch={this.onSearch}
        onSelect={this.onSelect}
        value={value}
        options={options}
      >
        <Input
          // style={{ height: 41 }}
          placeholder='Search...'
          suffix={<SearchOutlined />}
        />
      </AutoComplete>
    );
  }
}

export default TopSearchBar;
