import CRUDFactory, { API_URL } from "./crud_factory";

type LaborRate = {
  id: number;
  rate: number;
  fleetHaulRegionId: number;
  startDate: string;
  endDate: string;
  month: string;
  streamTypeId: number;
};
export type GetLaborRatesResponse = LaborRate[];
interface LaborRateFactoryType extends CRUDFactory {
  get(params?: {}): Promise<GetLaborRatesResponse>;
}
const LaborRateFactory = new CRUDFactory({
  baseUrl: `${API_URL}labor_rates/`,
}) as LaborRateFactoryType;
export default LaborRateFactory;