import CRUDFactory from './crud_factory';
import { postRequest } from '../api/client';

class FleetHaulRouteStopCRUDFactory extends CRUDFactory {
  controllerUrl = '/fleet_haul_route_stops';
  constructor(props: any) {
    super(props);
  }
  createMany(
    fleetHaulServiceIds: number[],
    fleetHaulRouteId: number,
    options: any = null
  ) {
    return postRequest(
      `
    ${this.controllerUrl}/create_many`,
      {
        fleet_haul_route_id: fleetHaulRouteId,
        fleet_haul_service_ids: fleetHaulServiceIds,
      },
      options
    );
  }
}

export default new FleetHaulRouteStopCRUDFactory({
  baseUrl: '/api/private/v4/fleet_haul_route_stops/',
});
