import CRUDFactory, { Props } from './crud_factory';
import { json2param, defaultHeader, fetchHandler } from '../utils';

type Note = {
  id: number;
  content: string;
  whodunnit: { id: number; name: string };
  updatedAt: string;
};
export type NoteGetResponse = Note[];
export type NoteAddResponse = Note;
export type NoteDeleteResponse = {};
export type NoteUpdateResponse = NoteAddResponse;
export type NoteCreateManyResponse = NoteAddResponse[];
interface NoteCrudFactoryType extends CRUDFactory {
  get(options: {}): Promise<NoteGetResponse>;
  add(options: {}): Promise<NoteAddResponse>;
  delete(id: number): Promise<NoteDeleteResponse>;
  update(options: {}, id: number): Promise<NoteUpdateResponse>;
  createMany(data: {}, options?: { notify?: boolean }): Promise<NoteCreateManyResponse>;
}

class NoteCRUDFactory extends CRUDFactory {
  controllerUrl: string;

  constructor(props: Props) {
    super(props);
    this.controllerUrl = '/notes';
  }

  createMany(data: {}, options: { notify?: boolean } = {}) {
    const paramString = json2param(data);
    return fetch(`${this.controllerUrl}/create_many?${paramString}`, {
      credentials: 'same-origin',
      headers: defaultHeader(),
      method: 'POST',
      body: JSON.stringify(data),
    }).then((result) => fetchHandler(result, { notify: options.notify })) as Promise<NoteCreateManyResponse>;
  }
}
const NoteFactory = new NoteCRUDFactory({
  baseUrl: '/api/private/v4/notes/'
}) as unknown as NoteCrudFactoryType;
export default NoteFactory;
