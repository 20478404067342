import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { trimToEmpty, trimToNull } from 'SharedDirectory/utils/string_utils';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * @summary Commonly used functions for date formatting.
 */

// enable the LocalizedFormat plugin
// https://day.js.org/docs/en/plugin/localized-format
dayjs.extend(localizedFormat);

export const UNIVERSAL_DATE_FORMAT = 'YYYY-MM-DD';
// this satisfies both YYYY-MM-DD and YYYY-DD-MM date formats
const DATE_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}$/;

/**
 * Validates that the given date is a valid date in universal date format <tt>YYYY-MM-DD</tt>.
 *
 * While generally we can rely on dayjs.isValid() function for this purpose, in some causes it doesn't return expected
 * responses. e.g. The date <tt>2023-13-03</tt> in universal date format is interpreted as <tt>2024-01-03</tt> which
 * while seems smart, is still very confusing to the users.
 *
 * @see https://github.com/iamkun/dayjs/issues/320
 * @param dateStr
 */
const isValidUniversalDate = (dateStr: string) => {
  const trimmedDateString = trimToEmpty(dateStr);
  let isValid = true;
  // RegExp.exec returns an array or matches if any, otherwise it returns null
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
  if (!RegExp(DATE_FORMAT_REGEX).exec(trimmedDateString)) {
    isValid = false;
  } else {
    const dateObject = dayjs(trimmedDateString, UNIVERSAL_DATE_FORMAT);
    const dateEpochMillis = dateObject.valueOf();
    if (!dateEpochMillis && dateEpochMillis !== 0) {
      isValid = false; // NaN value, Invalid date
    } else {
      isValid = dateObject.format(UNIVERSAL_DATE_FORMAT) === trimmedDateString;
    }
  }

  return isValid;
};

/**
 * Formats the given date string in the supplied date format.
 *
 * @param dateStr The date string to be formatted
 * @param desiredDateFormat The desired date format
 * @return {string} the formatted date, <tt>null</tt> if the supplied date string is not a valid date
 */
export const formatDate = (
  dateStr: string | Date,
  desiredDateFormat: string
): string => {
  const dateObject = dayjs(trimToNull(dateStr));
  return dateObject.isValid() ? dateObject.format(desiredDateFormat) : null;
};

/**
 * Formats the supplied date in localized format if a value is supplied or the supplied default
 * value is returned. If not default value is supplied, <tt>null</tt> is returned.
 * <p>
 * See <a href='https://day.js.org/docs/en/display/format#localized-formats'>
 * Day.js Localized format</a> for more information on localized formats.
 * </p>
 * @param dateStr The date string to format, e.g. <tt>2018-08-16</tt>
 * @param defaultValue The value to return if the supplied date string is
 * <tt>null/undefined/</tt>empty, e.g. <tt>--</tt>
 * @returns localized formatted date string per function description above.
 */
const formatDateLocalized = (dateStr: string, defaultValue?: string) =>
  formatDate(dateStr, 'L') || defaultValue?.trim() || null;

/**
 * Formats the supplied period in localized formatted period begin and end dates separated by
 * <tt>' to '</tt> (without quotes).
 * <p>
 * See <a href='#formatDateLocalized'> for period begin and end dates handling. The value
 * <tt>'--'</tt> (without quotes) is supplied as the default formatted date value for period begin
 * and end dates.
 * </p>
 * @param periodBeginDateStr The period begin date string, e.g. <tt>2018-08-16</tt>
 * @param periodEndDateStr The period end date string, e.g. <tt>2018-12-20T11:06:38.012-05:00</tt>
 * @returns localized formatted period string per function description above. e.g.
 * <tt>08/16/2018 to 12/20/2018</tt>
 */
const formatPeriodLocalized = (
  periodBeginDateStr: string,
  periodEndDateStr: string
) => {
  const periodBeginFormatted = formatDateLocalized(periodBeginDateStr, '--');
  const periodEndFormatted = formatDateLocalized(periodEndDateStr, '--');

  return [periodBeginFormatted, periodEndFormatted].join(' to ');
};

/**
 * Formats the supplied date string in <tt>YYYY-MM-DD</tt> format.
 * @see https://learn.microsoft.com/en-us/globalization/locale/date-formatting#universal-date-format
 * @param dateStr the date string to format
 * @return the date string formatted in said date format.
 */
const formatDateUniversal = (dateStr: string): string => {
  return formatDate(dateStr, UNIVERSAL_DATE_FORMAT);
};

/**
 * Formats the supplied date string in <tt>MMM YYYY</tt> format.
 *
 * @param dateStr the date string to format
 * @return the date string formatted in said date format.
 */
const formatDateMonYearFormat = (dateStr: string): string => {
  const dateObject = dayjs(trimToNull(dateStr));
  return dateObject.isValid() ? dateObject.format('MMM YYYY') : null;
};

/**
 * A utility function to get a dayjs object representing the day before the supplied date string.
 *
 * @param dateStr The date string in {@link #UNIVERSAL_DATE_FORMAT}.
 * @return {Dayjs} the dayjs object representing the date before the supplied date if valid, <tt>null</tt> otherwise
 */
const theDayBefore = (dateStr: string): Dayjs => {
  const dateObject: Dayjs = dayjs(trimToNull(dateStr), UNIVERSAL_DATE_FORMAT);
  return dateObject.isValid() ? dateObject.subtract(1, 'day') : null;
};

// Convert "YYYY-MM-DD" date string to moment
const formatDateToMoment = <S = string>(date: string) =>
  formatDateUniversal(date) as S;

// Convert date object to long-form date string
const longFormDate = (date: string) => {
  const toReturn = formatDate(date, 'MMMM YYYY');
  return toReturn ?? date;
};

/**
 * Convert date object to <tt>MM DD YYYY</tt> string while separating by the supplied separator.
 * If no separator is given, it uses <tt>/</tt> as separator.
 * @param d The date to format
 * @param separator The separator string to use.
 */
const dateString = (d: string | Date, separator?: string) => {
  const separatorToUse = separator || '/';
  const dateFormat = `MM${separatorToUse}DD${separatorToUse}YYYY`;
  const formatted = formatDate(d, dateFormat);
  return formatted ?? (d as string);
};

// Return true if date is in the future
const futureDate = (date: string) =>
  dayjs(date, UNIVERSAL_DATE_FORMAT) > dayjs().startOf('month');

/**
 * @description format a timestamp for user's current timezone
 */
const formatTimeStamp = (timestamp: string, format?: string) => {
  const guessedTZ = dayjs.tz.guess();
  return dayjs
    .tz(timestamp, 'GMT-0')
    .tz(guessedTZ)
    .format(format ?? 'MMMM DD YYYY, h:mm:ss a');
};

export {
  formatDateLocalized,
  formatDateMonYearFormat,
  formatDateUniversal,
  formatPeriodLocalized,
  isValidUniversalDate,
  theDayBefore,
  formatDateToMoment,
  longFormDate,
  dateString,
  futureDate,
  formatTimeStamp,
};
