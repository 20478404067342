import styled from 'styled-components';

const ButtonGroupWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  width: 100%;
`;

const ButtonGroupWrapperInline = styled(ButtonGroupWrapper)`
  grid-template-columns: repeat(3, 1fr);
`;

export {
  ButtonGroupWrapper,
  ButtonGroupWrapperInline,
};
