import CRUDFactory, { API_URL } from "./crud_factory";

type FleetHaulRoute = {
  id: number;
  estTimeOfRoute: number
};
type GetFleetHaulRoutesResponse = FleetHaulRoute[];
interface FleetHaulRouteFactoryType extends CRUDFactory {
  get(params: {}): Promise<GetFleetHaulRoutesResponse>;
}
const FleetHaulRouteFactory = new CRUDFactory({
  baseUrl: `${API_URL}fleet_haul_routes/`,
}) as FleetHaulRouteFactoryType;
export default FleetHaulRouteFactory;