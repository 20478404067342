import { ApolloClient, ApolloClientOptions } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { ApolloLink, from, Operation } from 'apollo-link';
import { InMemoryCache, InMemoryCacheConfig } from 'apollo-cache-inmemory';
import ActionCable from 'actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { getCookie } from '../utils';
import { OperationDefinitionNode } from 'graphql';

type ClientOptions = {
  typeDefs?: ApolloClientOptions<{}>['typeDefs'];
  resolvers?: ApolloClientOptions<{}>['resolvers'];
  cacheOpts?: Partial<InMemoryCacheConfig>;
  url?: string;
  assumeImmutableResults?: boolean;
};

const cable = ActionCable.createConsumer('/cable');

const buildClient = ({ typeDefs, resolvers, cacheOpts, url }: ClientOptions = {}) => {
  const graphqlUrl = url || global.GEMINI_API_URL;
  const cache = new InMemoryCache({
    ...cacheOpts,
    freezeResults: true,
  });
  // create the links
  const httpLink = createHttpLink({ uri: graphqlUrl });
  let queryLinks = from([httpLink]);
  // Disable persisted queries in development
  // NOTE: webpack manages this variable
  // https://webpack.js.org/configuration/mode/
  if (process.env.NODE_ENV !== 'development') {
    const persistedLink = createPersistedQueryLink({});
    queryLinks = from([persistedLink, httpLink]);
  }

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = getCookie('jwt_token');
    operation.setContext((context: {
      headers: { [key: string]: string };
    }) => {
      const { headers } = context;
      return {
        ...context,
        headers: {
          ...headers,
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      };
    });

    return forward(operation);
  });
  const hasSubscriptionOperation = ({ query: { definitions } }: Operation) =>
    definitions.some(
      ({ kind, operation }: OperationDefinitionNode) =>
        kind === 'OperationDefinition' && operation === 'subscription'
    );

  const combinedlink = ApolloLink.split(
    hasSubscriptionOperation,
    new ActionCableLink({ cable }),
    queryLinks
  );
  const links = [authMiddleware, combinedlink];

  return new ApolloClient({
    link: from(links),
    assumeImmutableResults: true,
    cache,
    resolvers,
    typeDefs,
  });
};

export default buildClient;
