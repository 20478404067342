import CRUDFactory, { API_URL, Props } from './crud_factory';
import { defaultHeader, fetchHandler } from '../utils';
import { deleteRequest, postRequest } from '../api/client';

type Cbbs = {
  id: number;
  createdAt: string;
  costBasedServiceId: number;
  streamTypeId: number;
  occurrenceStartDate: string;
  occurrenceEndDate: string;
  periodStartDate: string;
  periodEndDate: string;
  billToLocationId: number;
  cost: number;
  binQuantity: number;
  binSize: number;
  removalType: string;
};
type Rbbs = {
  id: number;
  createdAt: string;
  billToLocationId: number;
  cost: number;
  rateBasedServiceId: number;
  occurrenceDate: string;
  haulDate: string;
  tonsServiced: number;
  tonsServicedOverride: number;
  tonnageRate: number;
  haulRate: number;
  tonsBilled: number;
  streamTypeId: number;
  removalType: string;
  binSize: number;
  binQuantity: number;
  referenceNumber: string;
  noHaul: boolean;
  missingTonsServiced: boolean;
  capTonnage: number;
  floorTonnage: number;
  rootId: number;
  intacctAccountId: string;
};
type BillCharge = {
  id: number;
  createdAt: string;
  occurrenceDate: string;
  chargeDate: string;
  billChargeType: string;
  intacctAccountId: string;
  billChargeRepositoryId: number;
  appliedTo: string;
  amount: number;
  description: string;
  serviceType: string;
  serviceId: number;
  taxCode: string;
  billToLocationId: number;
};
type BillCredit = {
  id: number;
  createdAt: string;
  serviceType: string;
  serviceId: number;
  taxCode: string;
  creditType: string;
  billToLocationId: number;
  amount: number;
  occurrenceDate: string;
  creditDate: string;
  intacctAccountId: string;
  appliedTo: string;
  description: string;
  separateIntacctBill: boolean;
};
type BillDebit = {
  id: number;
  createdAt: string;
  serviceType: string;
  serviceId: number;
  debitType: string;
  taxCode: string;
  billToLocationId: number;
  occurrenceStartDate: string;
  occurrenceEndDate: string;
  periodStartDate: string;
  periodEndDate: string;
  amount: number;
  name: string;
  intacctAccountId: string;
  appliedTo: string;
};
type BillFee = {
  id: number;
  createdAt: string;
  feeType: string;
  billToLocationId: number;
  amount: number;
  intacctAccountId: number;
};
type Bill = {
  id: number;
  locationId: number;
  billNumber: string;
  haulerAccountId: number;
  intacctPushStatus: string;
  billType: string;
  monthlyPeriod: string;
  sumOfCostBasedServices: number;
  sumOfRateBasedServices: number;
  sumOfFees: number;
  sumOfTaxes: number;
  sumOfCharges: number;
  sumOfDebits: number;
  sumOfCredits: number;
  intacctBills: {
    id: number;
    intacctBillToExpenses: {
      billType: string;
    }[];
    billNumber: string;
    intacctVendorId: string;
    state: string;
    createdAt: string;
    dateDue: string;
    datePaid: string;
    totalAmount: number;
    totalPaid: number;
    totalDue: number;
    recordUrl: string;
    referenceNumber: string;
  }[];
  intacctSoTransactions: {
    id: number;
    transactionId: number;
    paymentStatus: string;
    recordUrl: string;
    referenceNumber: string;
  }[];
  billToLocations: {
    id: number;
    locationId: number;
    intacctPushStatus: string;
    totalAmount: number;
  }[];
  costBasedBillServices: Cbbs[];
  rateBasedBillServices: Rbbs[];
  billFees: BillFee[];
  billCharges: BillCharge[];
  billCredits: BillCredit[];
  billDebits: BillDebit[];
  locations: {
    id: number;
    regionId: number;
    account: {
      id: number;
      portfolioId: number;
    };
    accountName: string;
    accountId: number;
    name: string;
    rateBasedServices: {
      id: number;
    }[];
    costBasedServices: {
      id: number;
    }[];
    salesStatus: string;
    costBasedBillServices: Cbbs[];
    rateBasedBillServices: Rbbs[];
    billCharges: BillCharge[];
    billCredits: BillCredit[];
    billDebits: BillDebit[];
    billFees: BillFee[];
    displayName: string;
  }[];
  haulerAccount: {
    id: number;
    accountNumber: string;
    haulerId: number;
    hauler: {
      id: number;
      haulersDisplayName: string;
    };
  };
  billCategory: string;
  billDocumentFileName: string;
  billDocument: string;
  totalAmount: number;
  recurringAmount: number;
  haulerFeePercentage: number;
  createdAt: string;
  displayName: string;
  illegitimateBill: boolean;
  occurrenceDate: string;
  date: string;
  dueDate: string;
  dueUponReceipt: string;
  currentAmount: number;
  location: {
    roots: {
      rootType: string;
      createdAt: string;
    }[];
  };
};
type DisconnectIntacctResponse = { success: boolean; message: string; };
type SyncFromIntacctResponse = { success: boolean; message: string; };
type SearchInIntacctResponse = Bill;
type CreateBillResponse = Bill;
export type GetBillsResponse = Bill[];
interface BillFactoryType extends CRUDFactory {
  disconnectIntacct(billId: number): Promise<DisconnectIntacctResponse>;
  syncFromIntacct(billId: number): Promise<SyncFromIntacctResponse>;
  searchInIntacct(billNumber: string): Promise<SearchInIntacctResponse>;
  create(payload: {}, options?: { notify?: boolean }): Promise<CreateBillResponse>;
  get(params?: {}): Promise<GetBillsResponse>;
}
class BillFactory extends CRUDFactory {
  controllerUrl: string;

  constructor(props: Props) {
    super(props);
    this.controllerUrl = '/bills';
  }

  disconnectIntacct(billId: number) {
    return deleteRequest(`${this.controllerUrl}/${billId}/disconnect_intacct/`) as Promise<DisconnectIntacctResponse>;
  }

  syncFromIntacct(billId: number) {
    return postRequest(`${this.controllerUrl}/${billId}/fetch_from_intacct`) as Promise<SyncFromIntacctResponse>;
  }

  searchInIntacct(billNumber: string) {
    return postRequest(`${this.controllerUrl}/sync_intacct_for_one_bill`, {
      bill_number: billNumber,
    }) as Promise<SearchInIntacctResponse>;
  }

  create(payload: {}, options: { notify?: boolean } = {}) {
    return fetch(`${this.controllerUrl}`, {
      credentials: 'same-origin',
      headers: defaultHeader(),
      method: 'POST',
      body: JSON.stringify(payload),
    }).then((result) => fetchHandler(result, { notify: options.notify })) as Promise<CreateBillResponse>;
  }
}

export default new BillFactory({
  baseUrl: `${API_URL}bills/`,
}) as BillFactoryType;
