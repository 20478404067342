import gql from 'graphql-tag';
import TopSearchBar from '../presenters/top_search_bar';

export default gql`
  ${TopSearchBar.fragments.accountFields}
  ${TopSearchBar.fragments.locationFields}
  ${TopSearchBar.fragments.portfolioFields}
  query SearchAccounts($search: String!) {
    response: allAccounts(
      orderBy: []
      first: 10
      filter: { name: { includesInsensitive: $search } }
      customFilter: { orderByPosition: $search }
    ) {
      nodes {
        ...SearchAccountsFields
        portfolioByPortfolioId {
          ...SearchPortfoliosFields
        }
        locationsByAccountId {
          nodes {
            ...SearchLocationsFields
          }
        }
      }
    }
  }
`;
