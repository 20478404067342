import CRUDFactory, { API_URL } from './crud_factory';

type Region = {
  id: number;
  name: string;
};
type GetRegionsResponse = Region[];
interface RegionFactoryType extends CRUDFactory {
  get(params?: {}): Promise<GetRegionsResponse>;
};
const RegionFactory = new CRUDFactory({
  baseUrl: `${API_URL}fleet_haul_regions/`,
}) as RegionFactoryType;
export default RegionFactory;