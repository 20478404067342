// Download a file with JavaScript given a URI and filename
export const downloadFromUri = (uri: string, filename: string) => {
  const pom = document.createElement('a');
  pom.href = uri;
  pom.setAttribute('download', filename);
  document.body.appendChild(pom);
  pom.click();
  document.body.removeChild(pom);
  pom.remove();
};

// Create an element to download a file and then remove it
export const downloadFile = (
  content: string,
  filename: string,
  type = ''
) => {
  // webkitURL is not resolvable to URL type, it may be a mistake
  // should ask Adam
  window.URL = window.URL || <any>window.webkitURL;
  const docType = type || 'text/csv;charset=utf-8;';
  const blob = new Blob([content], { type: docType });
  downloadFromUri(window.URL.createObjectURL(blob), filename);
};
