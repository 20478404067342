import CRUDFactory, { API_URL } from "./crud_factory";

type Industry = {
  id: number;
  name: string;
};
export type GetIndustriesResponse = Industry[];
interface IndustryFactoryType extends CRUDFactory {
  get(params?: {}): Promise<GetIndustriesResponse>;
}
export default new CRUDFactory({
  baseUrl: `${API_URL}industries/`,
}) as IndustryFactoryType;
