import CRUDFactory, { API_URL } from './crud_factory';

type HaulerAccountsToLocation = {
  id: number;
};
type GetHaulerAccountsToLocationsResponse = HaulerAccountsToLocation[];
type CreateHaulerAccountsToLocationResponse = HaulerAccountsToLocation;
interface HaulerAccountsToLocationFactoryType extends CRUDFactory {
  get(params: {}): Promise<GetHaulerAccountsToLocationsResponse>;
  create(params: {}, options?: { notify: boolean }): Promise<CreateHaulerAccountsToLocationResponse>;
};
export default new CRUDFactory({
  baseUrl: `${API_URL}hauler_accounts_to_locations/`,
}) as HaulerAccountsToLocationFactoryType;