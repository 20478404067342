import CRUDFactory, { Props } from './crud_factory';
import ForecastMetaFactory from './forecast_meta_factory';
import { fetchHandler } from '../utils';
import { postRequest } from '../api/client';

type SnapshotAccount = {
  id: number;
  locations: {
    id: number;
    editable: boolean;
  }[];
  portfolio: {
    id: number;
    customerName: string;
  };
  doc_ready: boolean;
  possible_sf_accts: {
    id: string;
    accountName: string;
    parentAccount: {
      accountName: string;
    };
    accountId?: number;
  }[];
};
type SnapshotProposal = {
  id: number;
  status: string;
  matchmaker_enabled: boolean;
  loa_ready: boolean;
  note: {
    id: number;
    content: string;
    noteable_id: number;
    noteable_type: string;
  };
  sales_mma_file_name: string;
  sales_mma_file_url: string;
  sales_proposal_file_name: string;
  sales_proposal_file_url: string;
};
export type FetchFromProposalResponse = {
  accounts: SnapshotAccount[];
  proposal: SnapshotProposal;
};
export type FetchFromLocationsResponse = {
  accounts: SnapshotAccount[];
};
export type TakeSnapshotResponse = {
  message: string;
  job_id: string;
};
class SnapshotCRUDFactory extends CRUDFactory {
  snapshot_url: string;
  orion_url: string;
  createSnapshotUrl: string;
  checkSnapshotUrl: string;
  existingProposalUrl: string;
  locked: boolean;

  constructor(props: Props) {
    super(props);
    this.snapshot_url = '/forecast_snapshot';
    this.orion_url = '/orion/forecast';
    this.createSnapshotUrl = '/orion/snapshot';
    this.checkSnapshotUrl = '/orion/hassnapshot';
    this.existingProposalUrl = '/orion/existing_proposals';
    this.locked = true;
  }

  fetchFromLocations(ids: number[]) {
    return postRequest<FetchFromLocationsResponse>(this.snapshot_url, {
      locationIds: ids,
    });
  }

  fetchFromProposal(id: number) {
    if (!id) throw Error('Unrecognized id');
    return fetch(`${this.snapshot_url}/${id}/get_proposal`, {
      credentials: 'same-origin',
    }).then(fetchHandler<FetchFromProposalResponse>);
  }

  fetchRawOrionData(
    locationIds: number[],
    cbsIds: number[],
    rbsIds: number[],
    fhsIds: number[]
  ) {
    const data = {
      locationIds,
      costBasedServiceIds: cbsIds,
      rateBasedServiceIds: rbsIds,
      fleetHaulServiceIds: fhsIds,
    };
    return postRequest<{ job_id: string; dash: string }>(this.orion_url, data);
  }

  fetchExistingProposals(locationIds: number[]) {
    const data = {
      locationIds,
    };
    return postRequest<{ servicesWithOpportunity: [] }>(
      `${this.existingProposalUrl}`,
      data
    );
  }

  takeSnapshot(locationIds: number[], updateExistingOpportunity = false) {
    const data = { locationIds, updateExistingOpportunity };
    return postRequest(
      this.createSnapshotUrl,
      data
    ) as Promise<TakeSnapshotResponse>;
  }

  checkHasSnapshot(locationIds: (number | string)[]) {
    const data = { locationIds };
    return postRequest<{ hassnapshot: boolean }>(this.checkSnapshotUrl, data);
  }

  update(params: Record<string, any>, id: string | number) {
    const resourceId = id;
    const forecastMetumId = params.id;
    if (Number.isNaN(parseInt(resourceId as string, 10))) {
      throw Error('Unrecongnized id');
    }
    if (this.locked) {
      if (forecastMetumId) {
        // update existing metum
        return ForecastMetaFactory.update(
          {
            ...params,
            forecast_snapshot_id: resourceId,
          },
          forecastMetumId
        );
      } else {
        // add a new metum
        return ForecastMetaFactory.add({
          ...params,
          forecast_snapshot_id: resourceId,
        });
      }
    }
    throw Error('Cannot update Snapshot');
  }
}
export default new SnapshotCRUDFactory({
  baseUrl: '/api/private/v4/forecast_snapshots/',
});
