interface Props {
  salesStatus: string;
  opsStatus: string;
}
const IntegrationStatusLabel = ({ salesStatus, opsStatus }: Props) => {
  let color = 'gray';
  switch (salesStatus) {
    case 'Open':
      color = 'orange';
      break;
  }
  switch (opsStatus) {
    case 'Integrated':
      color = 'blue';
      break;
    case 'Failed Integration':
      color = 'red';
      break;
  }
  const text =
    !opsStatus || opsStatus === 'Pending Integration' ? salesStatus : opsStatus;

  return <strong style={{ color }}>{text}</strong>;
};

export default IntegrationStatusLabel;
