import { render } from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { UserContext } from 'SharedDirectory/contexts';
import { TopBarController } from 'SharedDirectory/components/nav_bar';
import postgraphileClient from '../shared/clients/postgraphile';

const renderTopBar = () => {
  render(
    <ApolloProvider client={postgraphileClient()}>
      <UserContext.UserProvider>
        <TopBarController />
      </UserContext.UserProvider>
    </ApolloProvider>,
    document.getElementById('antd-nav-bar')
  );
};

export default renderTopBar;
