import { formatPercentage } from 'SharedDirectory/utils/number_utils';

// Format number depending on trailing digits
const formatNumber = (
  value: number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) => {
  if (value !== undefined) {
    return value.toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  }
  return value;
};

// Format number of dollar values depending on cell metric
const formatCellValue = (
  rowData: { metric: string },
  columnKey: string,
) => {
  let value: string | number = rowData[columnKey as keyof typeof rowData];
  let { metric } = rowData;

  metric = metric || columnKey;

  if (value !== undefined) {
    value = parseFloat(value) as number;

    const dollarMetrics = [
      'Delta',
      'Won Deal',
      'Cost',
      'Amount',
      'Revenue',
      'Rate',
      'MRR',
      'Goal',
    ];

    const percentMetrics = ['%', 'Percent'];

    const isDollarMetric =
      metric &&
      dollarMetrics.some((dollarMetric) => metric.includes(dollarMetric));

    const isPercentMetric =
      metric &&
      percentMetrics.some((percentMetric) => metric.includes(percentMetric));

    if (isPercentMetric) {
      value = `${formatNumber(value)}%`;
    } else if (isDollarMetric) {
      if (value < 0.0) {
        value = `-$${formatNumber(Math.abs(value))}`;
      } else if (metric) {
        const formatted = `$${formatNumber(value)}`;
        value = metric.includes('to Goal') ? `+${formatted}` : formatted;
      }
    } else {
      value = formatNumber(value);
    }
  }

  return value;
};

const unCamelCase = (s: string) =>
  s
    // insert a space between lower & upper
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    // space before last upper in a sequence followed by lower
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    // uppercase the first character
    .replace(/^./, (str) => str.toUpperCase());

const titleCase = (str: string) => {
  let strArr = str.split('_');
  strArr = strArr.map((s, i) => {
    if (i > 0) {
      return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
    }
    return s;
  });

  return unCamelCase(strArr.join(''));
};

function formatPhoneNumber(s: string) {
  const s2 = `${s}`.replace(/\D/g, '');
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : `(${m[1]}) ${m[2]}-${m[3]}`;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

// Compare two lower case strings
const compareLowerCase = (a: string, b: string) =>
  a && b && a.toLowerCase().localeCompare(b.toLowerCase());

/**
 * @description format number to 2nd decimal value as represented by graphql string
 * Ex: 15 -> '15.0', 15.025 -> '15.03', 15.00 -> '15.0', 1346.2321 -> '1346.23'
 */
const decimalString = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
  useGrouping: false,
});
const customDecimalString = (min = 1, max = 2, group = true) =>
  new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: min,
    maximumFractionDigits: max,
    useGrouping: group,
  });

export {
  formatNumber,
  formatPercentage,
  formatCellValue,
  unCamelCase,
  formatPhoneNumber,
  titleCase,
  currencyFormatter,
  compareLowerCase,
  decimalString,
  customDecimalString,
};
