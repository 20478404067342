import { ColumnProps } from 'antd/lib/table';
import changeCase from 'change-case';
import { dig, isFunction } from '.';

interface GenerateColumnsProps<T> {
  names: string[];
  hiddenColumns: string[];
  columnRender: (key: string, value: any, record: T) => JSX.Element | string | number;
  columnToText: { [x: string]: string | ((v: string) => string) };
  fixedColumns: string[];
  getColumnTitle: (key: string) => string | React.ReactElement;
  onCell: (r: T, key: string) => any;
}

export const generateColumns = <T extends {}>({
  names = [],
  hiddenColumns = [],
  columnRender,
  columnToText,
  fixedColumns = [],
  getColumnTitle,
  onCell,
}: GenerateColumnsProps<T>) => {
  const autoColumns: ColumnProps<T>[] = names
    .filter((key) => !hiddenColumns.includes(key))
    .map((key) => ({
      key,
      title: isFunction(getColumnTitle)
        ? getColumnTitle(key)
        : changeCase.titleCase(key),
      dataIndex: key,
      onCell: onCell ? (record) => onCell(record, key) : null,
      render: (value, record) => {
        // Custom column render
        if (columnRender) {
          let toRender = null;
          const ctt = columnToText[key];
          if (isFunction(ctt)) {
            const newValue = ctt(value);
            if (newValue) {
              toRender = columnRender(key, newValue, record);
            } else {
              toRender = columnRender(key, value, record);
            }
          } else {
            toRender = columnRender(key, value, record);
          }
          if (toRender) {
            return toRender;
          }
        }

        // Display arrays as strings joined on commas
        if (Array.isArray(value)) {
          return value.join(', ');
        }

        return value === 0 ? value : value || 'N/A';
      },
      fixed: fixedColumns.includes(key) ? 'left' : null,
    }));
  return autoColumns;
};

const parseText = (text: string, type?: string) => {
  let value: any = text;
  if (type === 'number') {
    // ints
    value = parseFloat(text);
  }
  return value;
};

export const columnSorter = <T extends AnyObject>(
  a: T,
  b: T,
  key: keyof T,
  type: string,
  toText: (s: string) => string
) => {
  // Generic column sorting
  let first: any = a[key] === 0 ? a[key] : a[key] || 'N/A';
  let second: any = b[key] === 0 ? b[key] : b[key] || 'N/A';

  if (first && typeof first === 'object' && first.props) {
    first = parseText(first.props.text);
  }

  if (second && typeof second === 'object' && second.props) {
    second = parseText(second.props.text);
  }

  switch (type) {
    case 'number': {
      // Ints
      return first - second;
    }
    case 'date': {
      // Dates
      // @ts-ignore
      return new Date(first) - new Date(second);
    }
    case 'array': {
      // Arrays
      const v1 = first && first !== 'N/A' ? first.join(', ') : 'N/A';
      const v2 = second && second !== 'N/A' ? second.join(', ') : 'N/A';
      return v1.localeCompare(v2);
    }
    case 'object': {
      if (isFunction(toText)) {
        return toText(first).localeCompare(toText(second));
      }
      // object type, we have no idea how to compare;
      return first > second;
    }
    default:
      // String
      return first.localeCompare(second);
  }
};

interface AddClientSorterProps<T> {
  columns: ColumnProps<T>[];
  sortColumns: string[];
  columnTypes: { [x: string]: string };
  columnToText: { [x: string]: (v: string) => string };
}

export const addClientSorter = <T extends {}>({
  columns = [],
  sortColumns = [],
  columnToText = {},
  columnTypes = {},
}: AddClientSorterProps<T>) => {
  const newColumns = columns.map((column) => {
    const { key } = column;
    if (sortColumns.includes(`${key}`)) {
      const func = columnToText[key];
      const toReturn = { ...column };
      toReturn.sorter = (a, b) =>
        columnSorter(a, b, `${key}` as keyof T, columnTypes[key], func);
      return toReturn;
    }
    return column;
  });
  return newColumns;
};

export const generateSortColumn = <T extends {}>(
  path: string[],
  dataType: 'number' | 'string' = 'string'
) => {
  return (a: T, b: T) => {
    const value1 = dig<string>(path, a) || '';
    const value2 = dig<string>(path, b) || '';
    switch (dataType) {
      case 'number':
        return Number(value1) - Number(value2);
      default:
        return `${value1}`.localeCompare(`${value2}`);
    }
  };
};
