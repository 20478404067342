import { notification } from 'antd';
import { genericErrorTranslator } from '../error_translators';
import { HttpRequestHeader } from 'antd/lib/upload/interface';

interface SuccessResponse<T> extends Response {
  json<P = T>(): Promise<P>;
}
interface ErrorResponse extends Response {
  json: () => Promise<Error>;
}
export type TypedResponse<T = any> = SuccessResponse<T> | ErrorResponse;
// Typeguard
export const isSuccessResponse = <T>(
  response: TypedResponse<T>
): response is SuccessResponse<T> => {
  if (response.ok) {
    return true;
  }
  return false;
};

export const getCookie = (name: string) => {
  const cookieStrings = document.cookie.split('; ');
  return cookieStrings.reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

/**
 * @description A default handler that handles the fetch response.
 * Fetch response contain ok=false, which indicate network issue,
 * Or the data is actually error. The error could be a JSON response,
 * or may be a text response. This function should handle them and throw an error
 * correctly. Also it uses antd notification to display error.
 *
 * @param {Response} response
 * @param {object} options  optiosn to indiate to notify in browser or not.
 */
export const fetchHandler = <T>(
  response: TypedResponse<T>,
  options: any = {}
) => {
  if (isSuccessResponse<T>(response)) {
    return response.json();
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json().then((err) => {
      if (options.notify) {
        notification.error({
          message: 'Error',
          description: genericErrorTranslator(err),
        });
      }

      return Promise.reject(
        Error(`Unsuccessful. Error message: ${err.message}`)
      );
    });
  }
  return response.text().then((err) => {
    if (options.notify) {
      notification.error({
        message: 'Error',
        description: err.substring(0, 200),
      });
    }
    return Promise.reject(Error(`Unsuccessful. Error message: ${err}`));
  });
};

/**
 * @description module to resolve any web or potentially user related tokens
 */
export const csrfToken = (): string => {
  const csrfTokenElement: HTMLElement & { content?: string } =
    document.getElementsByName('csrf-token')[0];
  if (csrfTokenElement) {
    return csrfTokenElement.content;
  }
  return '';
};

/**
 * @description A default header for fetch request. with XMLHttpRequest, ContentType set to json
 * @param {Object} options indicate to add csrf token, or if the fetch request contains FormData
 */
export interface DefaultHeaderProps {
  csrf?: boolean;
  formData?: boolean;
  acceptJson?: boolean;
  noCache?: boolean;
  notify?: boolean;
  id?: number;
}
export const defaultHeader = (
  options: DefaultHeaderProps = { csrf: true, formData: false }
) => {
  const {
    csrf = true,
    formData = false,
    acceptJson = true,
    noCache = false,
  } = options;
  const headers: HttpRequestHeader = {
    'Authorization': `Bearer ${getCookie('jwt_token')}`,
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  };
  if (acceptJson) {
    headers.Accept = 'application/json';
  }

  // specify request should not use browser cache
  if (noCache) {
    headers['Cache-Control'] = 'no-cache';
  }

  // by default set content type as JSON
  if (formData) delete headers['Content-Type'];

  // by default try and resolve csrf token
  if (csrf) headers['X-CSRF-Token'] = csrfToken();

  return headers;
};
