import * as React from 'react';

export interface HighlighterProps {
  text: string;
  searchText: string;
  highlightStyle?: React.CSSProperties;
}

const Highlighter = ({
  text,
  searchText,
  highlightStyle,
}: HighlighterProps) => {
  let regex = new RegExp(`(${searchText})(?![^<]*>|[^<>]*<)`, 'i');
  try {
    // this regex is faster but isn't supported in some browsers
    regex = new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i');
  } catch (e) {
    // do nothing
  }

  return (
    <>
      {text.split(regex).map((fragment: string, i: number) =>
        fragment &&
        searchText &&
        fragment.toLowerCase() === searchText.toLowerCase() ? (
          <span key={i} style={highlightStyle || { fontWeight: 800 }}>
            {fragment}
          </span>
        ) : (
          fragment
        )
      )}
    </>
  );
};

export default Highlighter;
