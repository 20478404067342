export const splitDays = (input: string): string[] => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const result: string[] = [];
  let remainingInput = input;

  for (const day of days) {
    if (remainingInput.includes(day)) {
      result.push(day);
      remainingInput = remainingInput.replace(day, '');
    }
  }

  return result;
};
