import { getRequest, postRequest } from '../api/client';

export interface ExpenseSchedule {
  document_number: string;
  record_no: number;
  record_url: string;
}
export type GetExistingExpenseSchedulesResponse = { data: ExpenseSchedule[] };
type PushHaulerExpenseScheduleAndBillResponse = { success: boolean; message: string };
class IntacctAccountsPayableFactory {
  props: {};
  url: string;

  constructor(props?: {}) {
    this.props = props;
    this.url = '/intacct_accounts_payable';
  }

  getExistingExpenseSchedules(billId: number | string) {
    return getRequest<GetExistingExpenseSchedulesResponse>(`${this.url}/find_expense_schedules?bill_id=${billId}`);
  }

  pushHaulerExpenseScheduleAndBill(billId: number | string) {
    return postRequest<PushHaulerExpenseScheduleAndBillResponse>(
      `${this.url}/push_hauler_expense_schedule_and_bill`,
      {
        bill_id: billId,
      }
    );
  }
}

export default new IntacctAccountsPayableFactory();
