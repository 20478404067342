import { dateString } from './date_utils';
import moment from 'moment';

type FilterValue = string | moment.Moment | (string | moment.Moment)[]
export type FilterOption<Value = FilterValue> = {
  id?: string;
  name?: string;
  label?: string;
  value?: Value;
};
export type FilterOptions<Value = FilterValue> = {
  multi?: boolean;
  name?: string;
  type?: string;
  options: FilterOption<Value>[];
};
type SelectedFilters = (FilterOption | FilterOption[])[];
type FileNameFilters = {
  [index: string]: SelectedFilters
};

// Map filters to a format that is digestable by the backend
export function mapSelectedFilters(selectedFilters: SelectedFilters): FilterOption[] {
  return selectedFilters
    .map((selector) => {
      if (Array.isArray(selector) && selector.length > 0) {
        return {
          name: selector[0].name.toLowerCase(),
          value: selector.map((innerValues) => innerValues.value as string | moment.Moment),
        };
      }

      if (selector !== undefined && !Array.isArray(selector)) {
        return {
          name: selector.name.toLowerCase(),
          value: selector.value,
        };
      }
      return null;
    })
    .filter((e) => e);
}

// Generate a filename for exported data with filters
export function filterFilename(startName: string, selectedFilters: FileNameFilters) {
  const BreakException = {};
  const keys = Object.keys(selectedFilters);
  let filterString = '';

  /* Two possible keys: include / exclude
   * Map keys into CSV title in the format:
   * (INCLUDE) [filters], (EXCLUDE) [filters] */
  keys.forEach((key) => {
    let keyFilterString = '';
    selectedFilters[key].forEach((f) => {
      if (Array.isArray(f)) {
        const filter = [...f];
        try {
          // Break array into title string
          filter.forEach((subfilter, idx) => {
            if (subfilter.label === 'All') throw BreakException; // don't include in title
            if (idx === 0) {
              keyFilterString += `${subfilter.name.toUpperCase()}=`;
            }
            keyFilterString += `${subfilter.label}; `;
          });
        } catch (e) {
          if (e !== BreakException) throw e;
        }
      } else if (f && f.label !== 'All' && f.value) {
        // Add single value to title string
        const filter = { ...f };

        keyFilterString += `${filter.name.toUpperCase()}=`;

        // Convert date to string
        if (filter.name.toLowerCase().includes('date')) {
          filter.label = dateString((filter.value as moment.Moment).toDate(), '_') as string;
        }

        keyFilterString += `${filter.label}; `;
      }
    });

    // If key has filters, add to title
    if (keyFilterString.length > 0) {
      filterString += `[${key.toUpperCase()}] ${keyFilterString}`;
    }
  });

  // Remove erroneous characters not allowed in filenames
  filterString = filterString.replace(/([^a-z0-9\s;_=[]]+)/gi, '');
  const pos = filterString.lastIndexOf(';');
  filterString = filterString.substring(0, pos).trim();

  // Add filter string to filename if there were filters applied
  let filename = startName;
  if (filterString.length > 0) {
    filename += ` - ${filterString}`;
  }

  // Make sure name isn't too long (Windows can't handle large filenames)
  if (filename.length > 200) {
    filename = filename.substring(0, 200);
  }

  filename += '.csv';
  return filename;
}
