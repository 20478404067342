import CRUDFactory, { API_URL } from "./crud_factory";

type Market = {
  id: number;
  city: string;
  state: string;
  abbreviation: string;
};
export type GetMarketsResponse = Market[];
interface MarketFactoryType extends CRUDFactory {
  get(params?: {}): Promise<GetMarketsResponse>;
}
export default new CRUDFactory({
  baseUrl: `${API_URL}markets/`
}) as MarketFactoryType;
