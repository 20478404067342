// given an object, assign and return a new FormData instance with the field values
// using the object keys and vals
/**
 * @description This function build a form data from a JSON object
 * It is a recursive function that handles nested JSON object too.
 * Started out to create a FormData object, and append data into it.
 * the root call of this function should only be a obj.
 * @param {object} obj The JSON object to be converted to FormData
 * @param {FormData|null} form The current Form object to work on, should be null at Root
 * @param {Sring|null} namespace The current namespace of the obj, should be null at Root
 * @returns {FormData}
 */
export const buildFormData = (
  obj: AnyObject,
  form?: FormData | null,
  namespace?: string
) => {
  const formData = form || new FormData();
  let formKey: string;

  Object.entries(obj).forEach(([k, v]) => {
    if (namespace) {
      // It's nested
      formKey = `${namespace}[${k}]`;
    } else {
      formKey = k;
    }
    if (Array.isArray(v)) {
      v.forEach((o) => {
        const tempFormKey = `${formKey}[]`;
        if (typeof o === 'object' && !(o instanceof File) && !!o) {
          buildFormData(o, formData, tempFormKey);
        } else if (o !== undefined) {
          formData.append(tempFormKey, o);
        }
      });
    } else if (typeof v === 'object' && !(v instanceof File) && !!v) {
      // need to recursively build form data;
      buildFormData(v, formData, formKey);
    } else if (v === null) {
      formData.append(formKey, '');
    } else if (v) {
      formData.append(formKey, v);
    } // Essentially ignores v === undefined case
  });
  return formData;
};

/**
 * @description Convert a file into DataUrl format, it is a base64 encode string.
 * @param {File} file The File object to be converted.
 * It is usually the value of FileInput html element
 * @returns {Promise<String>} base64 encoded data-url
 */

export const fileToDataUrl = (file: File) =>
  new Promise<string | ArrayBuffer>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve((<FileReader>event.target).result);
    };
    reader.readAsDataURL(file);
  });
