import { isNull, isUndefined } from 'lodash';

/**
 * Formats the given number as a percentage value including the % sign. e.g. <tt>.0497</tt> is formatted as <tt>4.97%</tt>.
 * Please note that the supplied value is expected to be the fractional percentage values (<tt>.01</tt> is <tt>1%</tt>).
 * @param num The number to be formatted.
 * @return {string} the localized percentage value including the percentage sign. <tt>null</tt> or <tt>undefined</tt>
 * values are formatted as blank value to distinguish them from true zero percent values.
 */
const formatPercentage = (num: number): string => (
    isUndefined(num) || isNull(num)
        ? ''
        : num.toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2})
);

const valueOrZero = (value: string | number | null | undefined) => Number(value || 0);

export { formatPercentage, valueOrZero };
