import gql from 'graphql-tag';
import TopSearchBar from '../presenters/top_search_bar';

export default gql`
  ${TopSearchBar.fragments.accountFields}
  ${TopSearchBar.fragments.locationFields}
  ${TopSearchBar.fragments.portfolioFields}
  query SearchLocations($search: String!) {
    response: allLocations(
      orderBy: []
      first: 10
      filter: { displayName: { includesInsensitive: $search } }
      customFilter: { orderByPosition: $search }
    ) {
      nodes {
        ...SearchLocationsFields
        accountByAccountId {
          ...SearchAccountsFields
          portfolioByPortfolioId {
            ...SearchPortfoliosFields
          }
        }
      }
    }
  }
`;
