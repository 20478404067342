import json2csv from 'json2csv';
import changeCase from 'change-case';
import qs from 'query-string';
import { isFunction } from './bools';
import { getFieldType } from './graphql_helpers';
import { GraphqlTableColumnProps } from 'SharedDirectory/components/graphql_table/table/graphql_table_ts';
import { TableIntrospect } from '@local_types';

/**
 * @description from the query string in url, it is converted to a JSON object
 * This does not handle nested parameters.
 * @param {*} url
 */
export function parseQueryString(url: string) {
  const urlParams: { [x: string]: string } = {};
  if (!url) return urlParams;
  url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
    urlParams[$1] = $3;
    return '';
  });

  return urlParams;
}

// Return end of URL path
export function getEndOfUrlPath(url: string) {
  const parts = url.split('/');
  return parts.pop() || parts.pop();
}
/**
 * Recursive Build URL params from json object
 * Modify params (an array) in memory
 */
const buildParam = (
  prefix: string,
  currentObject: any,
  params: string[],
  fromArray = false
) => {
  const nextPrefix = fromArray ? `${prefix}[]` : prefix;
  // If the object is string, then we are done

  if (
    currentObject === false ||
    currentObject === null ||
    currentObject === 0
  ) {
    // If it is false, it is still a value, so record it
    // If it is null, it is still a value, record it
    params.push(
      `${encodeURIComponent(nextPrefix)}=${encodeURIComponent(currentObject)}`
    );
    return;
  }

  if (currentObject === undefined) {
    return;
  }
  // If the object is an array, then loop through it, and recursive build params for each element
  if (Array.isArray(currentObject)) {
    currentObject.forEach((ele) => {
      buildParam(nextPrefix, ele, params, true);
    });
    return;
  }
  // If the object is an object
  if (typeof currentObject === 'object') {
    Object.keys(currentObject).forEach((key) => {
      const value = currentObject[key];
      const newPrefix = nextPrefix === '' ? key : `${nextPrefix}[${key}]`;
      buildParam(newPrefix, value, params, false);
    });
    return;
  }
  if (currentObject) {
    // If it is not false, and it is evaluated to not false
    // (meaning it is any thing that is not null, undefined, NaN, etc)
    params.push(
      `${encodeURIComponent(nextPrefix)}=${encodeURIComponent(currentObject)}`
    );
  }
};
export const json2param = (data: AnyObject) => {
  const params: string[] = [];
  buildParam('', data, params, false);
  return params.join('&');
};

export const setParams = (params = {}) => {
  const queryParams = qs.parse(window.location.search, {
    arrayFormat: 'bracket',
  });
  return json2param({ ...queryParams, ...params });
};

/**
 * @description This function unmelts data. construct a data matrix from regular 3 column format
 * @param {Array} data An array of objects in the 3 columns format
 * @param {Object} columns object to specify the name of row, col, value properties.
 * @param {Function} elementFunc optional function to construct the element in matrix.
 * If not provided, simply use the value
 * @example converts [{x:x1,y:y1,z:v1},{x:x1,y:y2,z:v2}] to [{x:x1,y1:v1,y2:v2...}]
 * @example unmelt([{x:x1,y:y1,z:v1},{x:x1,y:y2,z:v2}],{row:"x",col:"y",value:"z"})
 */
export interface UnmeltColumn {
  row: string;
  col: string;
  value: string;
}
type UnmeltValue = string | number | { id: number | string; value: number | string };
export type UnmeltDataRow = AnyObject
export type UnmeltElementFunc<T> = (v: T, c: AnyObject) => UnmeltValue;
export const unmelt = <T>(
  data: UnmeltDataRow[],
  columns: UnmeltColumn,
  elementFunc?: UnmeltElementFunc<T>
) => {
  if (!columns || !columns.row || !columns.col || !columns.value) {
    throw Error('require object to specify column format ');
  }
  // Sort the data
  const sortedData = data.sort((v1, v2) => {
    if (v1[columns.row] > v2[columns.row]) {
      return 1;
    }
    if (v1[columns.row] < v2[columns.row]) {
      return -1;
    }
    return 0;
  });
  const allColumns = new Set();
  const toReturn: AnyObject[] = [];
  let currentRowId = '';
  let currentRow: { [x: string]: UnmeltValue } = {};
  const { row, col, value } = columns;
  sortedData.forEach((cell) => {
    if (cell[row] !== currentRowId) {
      // Construct new row
      currentRowId = cell[row];
      currentRow = {};
      toReturn.push(currentRow);
      currentRow[row] = currentRowId;
    }
    if (elementFunc) {
      currentRow[cell[col]] = elementFunc(cell[value], cell)
    } else {
      currentRow[cell[col]] = cell[value];
    }
    allColumns.add(cell[col]);
  });
  return { matrixData: toReturn, columns: allColumns };
};
/**
 * @description This function converts a Graphql data array into csv format. It is mainly used
 * in the "export" function in a table, where the data is fetched through GraphQL,
 * and we defined Columns for the Antd Table. From the column, it should have some way to
 * tell us how to generate a text for a cell. This is either by a toText() in that column, or
 * by having the key of the column be the same as a GraphQlField of the data. (The field
 *  must be a scalar field)
 * @param {{Array}} fields The GraphqlFields that the rows of data contains, it describes the
 * structure of the data
 * @param {{Array}} columns The array of column object passed into GraphQLTable, if a toText()
 * is specified,
 * it will be used to generate the text for that cell
 * @param {{Array}} data The array of records fetched from graphql server
 * @param {{Array}} csvDataCallback A function that is called to modify the graphql data before
 * the process
 * @returns A csv styled text with header equal to the column titles.
 */
export interface Table2CsvPros<T> {
  fields: TableIntrospect['__type']['fields'];
  columns: GraphqlTableColumnProps<T, any>[];
  data: T[];
  csvDataCallback?: (d: T[]) => any[];
}
export const table2Csv = <T>(props: Table2CsvPros<T>): string => {
  const jsonData = table2Json(props);
  return json2csv(jsonData);
};

export const table2Json = <T extends AnyObject>(
  props: Table2CsvPros<T>
): json2csv.Options<{ [x: string]: string }> => {
  const { fields, columns, data, csvDataCallback } = props;
  const tableData = csvDataCallback ? csvDataCallback(data) : data;

  // Figure out the columns that can be exported, some columns are just buttons
  // A column can be exported if the field is "SCALAR", or the column as a "toText" function
  const tableColumns = columns.filter((column) => {
    const key = column.key;
    const field = fields.find((f) => {
      return f.name === key;
    });
    if (field) {
      // If it's scalar, then keep it
      const type = getFieldType(field);
      if (type.kind === 'SCALAR') return true;
    }
    if (isFunction(column.toText)) return true;
    return false;
  });

  if (tableData && tableData.length) {
    const fieldNames = tableColumns.map((c) => {
      return `${c.title && typeof c.title === 'string'
        ? c.title
        : changeCase.titleCase(c.key)
        }`;
    });
    const fieldKeys = tableColumns.map((c) => {
      return c.key;
    });
    // Process the data, some fields may not be directly exportable
    const newData = data.map((d) => {
      const newRow: { [x: string]: string } = {};
      tableColumns.forEach((column) => {
        // If there's some convertion needed
        const key: string = column.key;
        if (isFunction(column.toText)) {
          newRow[key] = `${column.toText(d[key], d)}`;
        } else {
          newRow[key] = d[key];
        }
      });
      return newRow;
    });

    return { fieldNames, data: newData, fields: fieldKeys };
  }
  return { data: [] };
};
/**
 *
 * @param {number} value
 * the function is required for SelectWidget in CustomForm component
 */

export function asNumber(value: string) {
  if (value === '') {
    return undefined;
  }
  if (/\.$/.test(value)) {
    // "3." can't really be considered a number even if it parses in js. The
    // user is most likely entering a float.
    return value;
  }
  if (/\.0$/.test(value)) {
    // we need to return this as a string here, to allow for input like 3.07
    return value;
  }
  const n = Number(value);
  const valid = typeof n === 'number' && !Number.isNaN(n);

  if (/\.\d*0$/.test(value)) {
    // It's a number, that's cool - but we need it as a string so it doesn't screw
    // with the user when entering dollar amounts or other values (such as those with
    // specific precision or number of significant digits)
    return value;
  }

  return valid ? n : value;
}

/**
 * @description Converts a regular object data into form data. It is particularly
 * useful when we try to send data that include files and other x-www form data
 * that cannot be simply put in a json format. used in ModelGraphqlForm when trying
 * to submit the form data that include files
 * @author Han Lai
 */
export const jsonToFormData = (data: object) => {
  const formData = new FormData();
  Object.entries(data).forEach(([k, v]) => {
    formData.append(k, v);
  });
  return formData;
};

export const enumToOptions = (data: object) => {
  return Object.entries(data).map(([key, value]) => {
    const text = changeCase.sentenceCase(key);
    return { text, key, value };
  });
};

export const removeTypename = <T extends AnyObject>(v: T) => {
  const dup = { ...v };
  delete dup['__typename' as any];
  return dup;
};
