import CRUDFactory, { API_URL } from "./crud_factory";

type LocationIgnoreHaulerContract = {
  id: number;
  locationId: number;
  acknowledgeNoContract: boolean;
};
export type CreateLocationIgnoreHaulerContractResponse = LocationIgnoreHaulerContract;
export type UpdateLocationIgnoreHaulerContractResponse = LocationIgnoreHaulerContract;
interface LocationIgnoreHaulerContractFactory extends CRUDFactory {
  create(params: {}): Promise<CreateLocationIgnoreHaulerContractResponse>;
  update(params: {}, id?: number): Promise<UpdateLocationIgnoreHaulerContractResponse>;
}
export default new CRUDFactory({
  baseUrl: `${API_URL}location_ignore_hauler_contracts/`,
}) as LocationIgnoreHaulerContractFactory;
