// Basic object to store data in table
/**
 * @class FixTableDataStore
 * @requires data: a list of objects. For a 2D table, each object in data needs to represent a row
 * @requires column: an array of string representing the columns displayed in table
 * @returns instance of the class. Used mainly for fix-data-table-2 npm
 * @link https://github.com/schrodinger/fixed-data-table-2
 */
class FixTableDataStore {
  data: {}[];
  columns: string[];
  size: number;

  constructor(data: {}[], columns: string[]) {
    this.columns = columns;
    this.data = data;
    this.size = data.length;
  }

  getObjectAt(index: number) {
    if (index < 0 || index > this.size) {
      return undefined;
    }

    return this.data[index];
  }

  /**
   * Populates the entire cache with data.
   * Use with Caution! Behaves slowly for large sizes
   * ex. 100,000 rows
   */
  getAll() {
    if (this.data.length < this.size) {
      for (let i = 0; i < this.size; i += 1) {
        this.getObjectAt(i);
      }
    }
    return this.data.slice();
  }
}

export default FixTableDataStore;
