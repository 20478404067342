import { fetchHandler } from '../utils';
import { postRequest } from '../api/client';

const AuthorizationFactory = {
  baseUrl: '/users/',
  getMarketPricingEdit() {
    return fetch(`${this.baseUrl}/auth?page=market_pricing`, {
      credentials: 'same-origin',
    }).then(fetchHandler) as Promise<{ edit: boolean }>;
  },
  setAuthorization(role: string, table: string, columns: string[], action: string, value: string) {
    return postRequest('/api/authorizations/v4/', {
      role,
      table,
      columns,
      action,
      value,
    });
  },
};
export default AuthorizationFactory;
