/**
 * @description this function is used in redux action, it create an action that takes
 * several parameters
 * @param {String} type the action type, usually some constant string
 * @param {Array<String>} argNames the following parameters after the type is then put into
 * an array. Each of them represent the field that is needed to dispatch this action.
 * for example makeActionCreator('foo','id','payload') will create
 * an action that accepts an object with keys 'id','payload'.
 */

import { Action } from "redux";

function makeActionCreator(type: string, ...argNames: string[]) {
  return (actionData?: any) => {
    const action: Action & Record<string, any> = { type };
    argNames.forEach((arg) => {
      action[arg] = actionData[arg];
    });
    return action;
  };
}

export default makeActionCreator;
