import CRUDFactory, { Props } from './crud_factory';
import { postRequest } from '../api/client';
import { fetchHandler, defaultHeader } from '../utils';

type AddTagResponse = Promise<{ status: string }>;
type RemoveTagResponse = Promise<{ status: string }>;
class TagFactory extends CRUDFactory {
  controllerUrl: string;

  constructor(props: Props) {
    super(props);
    this.controllerUrl = '/tags';
  }

  addTag(data: {}, options = {}) {
    return postRequest(this.controllerUrl, data, options) as AddTagResponse;
  }

  removeTag(data: {}, options = {}) {
    return fetch(`${this.controllerUrl}/remove`, {
      method: 'DELETE',
      headers: defaultHeader(options),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then((resp) => fetchHandler(resp, options)) as RemoveTagResponse;
  }
}

export default new TagFactory({ baseUrl: '/api/private/v4/tags/' });
