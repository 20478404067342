import * as client from '../api/client';

export const API_URL = '/api/private/v4/';

function validateId(id: string) {
  if (Number.isNaN(parseInt(id, 10))) throw Error(`Unrecognized id: ${id}`);
}

export type Props = {
  baseUrl: string;
};
class CRUDFactory {
  props: Props;
  baseUrl: Props['baseUrl'];

  constructor(props: Props) {
    this.props = props;
    this.baseUrl = props.baseUrl;
    // trailing '/' check
    if (this.baseUrl.slice(-1) === '/') {
      this.baseUrl = this.baseUrl.slice(0, -1);
    }
  }

  getMeta(options?: {}) {
    return client.getRequest(`${this.baseUrl}/meta`, null, options) as Promise<{
      properties: Record<string, any>;
      class: string;
      order: string[];
      disabled?: string[];
      hidden?: string[];
      required?: string[];
    }>;
  }

  get(params?: {}, options?: {}) {
    return client.getRequest(this.baseUrl, params, options);
  }

  getById(id: number | string, params?: {}, options = {}) {
    validateId(id as string);
    return client.getRequest(`${this.baseUrl}/${id}`, params, options);
  }

  update(params: Record<string, any> & { id?: number | string }, id?: number | string, options = { notify: true }) {
    const nid = id || params.id;
    validateId(nid as string);
    return client.putRequest(`${this.baseUrl}/${nid}`, params, options);
  }

  add(params: {}, options = { notify: true }) {
    return client.postRequest(this.baseUrl, params, options);
  }

  create(...args: any[]) {
    // @ts-ignore
    return this.add(...args);
  }

  delete(id: number | string | number[], options = { notify: true }) {
    validateId(id as string);
    return client.deleteRequest(`${this.baseUrl}/${id}`, options);
  }
}

export default CRUDFactory;
