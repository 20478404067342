import styled from 'styled-components';

const repeatUseMax = (type: string) => ({ n, s, max }: {
  n: string | number;
  s: string | number;
  max: string | number;
}) => {
  const useMax = !!max ? max : s;
  let useN;
  if (type === 'cols') {
    useN = !!n ? n : 'auto-fill';
  } else if (type === 'rows') {
    useN = !!n ? n : 'auto';
  }
  return `repeat(${useN}, minmax(${s}, ${useMax}))`;
};
const Split = styled.div<{
  rows?: string;
  cols?: string;
  align?: string;
  justify?: string;
}>`
  display: grid;
  grid-template-columns: ${({ cols = 'auto' }) => {
    if (typeof cols === 'string') return cols;
    return repeatUseMax('cols')(cols);
  }};
  grid-template-rows: ${({ rows = 'auto' }) => {
    if (typeof rows === 'string') return rows;
    return repeatUseMax('rows')(rows);
  }};
  align-items: ${({ align }) => (!!align ? align : 'stretch')};
  justify-items: ${({ justify }) => (!!justify ? justify : 'stretch')};
`;
export default Split;
