/**
 * @summary a function to generate Famous Phrase 1-2-1 for Rate Based Service
 * @param binQuantity
 * @param binSize
 * @param frequency
 */
const systemDisplayAbbreviation = (
  binQuantity: string | number,
  binSize: string | number,
  frequency: string | number,
) => {
  let abbreviation = '';
  abbreviation += `${Number(binQuantity) || '--'} - `;

  if (binSize) {
    abbreviation += `${Number(binSize) || '--'} - `;
  } else {
    abbreviation += 'na -';
  }

  if (frequency) {
    abbreviation += `${Number(frequency || '--')} `;
  } else {
    abbreviation += 'na -';
  }
  return abbreviation;
};

export default systemDisplayAbbreviation;
