import CRUDFactory, { API_URL } from './crud_factory';

export const AccountContactFactory = new CRUDFactory({
  baseUrl: `${API_URL}account_contacts/`,
});
export const PortfolioFactory = new CRUDFactory({
  baseUrl: `${API_URL}portfolios/`,
});
export const CostBasedServiceFactory = new CRUDFactory({
  baseUrl: `${API_URL}cost_based_services/`,
});
export const RateBasedServiceFactory = new CRUDFactory({
  baseUrl: `${API_URL}rate_based_services/`,
});
// export const InternalContractFactory = new CRUDFactory({ baseUrl: `${API_URL}internal_contracts/` });
export const FleetHaulServiceFactory = new CRUDFactory({
  baseUrl: `${API_URL}fleet_haul_services/`,
});
export const StreamGroupFactory = new CRUDFactory({
  baseUrl: `${API_URL}stream_groups/`,
});
// this is customized now, not a simple crud factory
// export const RoutingPeriodServiceFactory = new CRUDFactory({ baseUrl: `${apiUrl}routing_period_services/` });
export const BeelineRoutingFactory = new CRUDFactory({
  baseUrl: `${API_URL}beeline_routings/`,
});
export const RecoveryFacilityOrganizationFactory = new CRUDFactory({
  baseUrl: `${API_URL}recovery_facility_organizations/`,
});
export const FleetHaulRegionFactory = new CRUDFactory({
  baseUrl: `${API_URL}fleet_haul_regions/`,
});
export const PlacemarkFactory = new CRUDFactory({
  baseUrl: `${API_URL}placemarks/`,
});
export const EngagementFactory = new CRUDFactory({
  baseUrl: `${API_URL}engagements/`,
});
export const CostBasedBillServiceFactory = new CRUDFactory({
  baseUrl: `${API_URL}cost_based_bill_services/`,
});
export const RateBasedBillServiceFactory = new CRUDFactory({
  baseUrl: `${API_URL}rate_based_bill_services/`,
});
export const BillFeeFactory = new CRUDFactory({
  baseUrl: `${API_URL}bill_fees/`,
});
export const BillChargeFactory = new CRUDFactory({
  baseUrl: `${API_URL}bill_charges/`,
});
export const BillDebitFactory = new CRUDFactory({
  baseUrl: `${API_URL}bill_debits/`,
});
export const BillCreditFactory = new CRUDFactory({
  baseUrl: `${API_URL}bill_credits/`,
});
export const DayAvailabilitieFactory = new CRUDFactory({
  baseUrl: `${API_URL}day_availabilities/`,
});
export const StreamDiversionFactory = new CRUDFactory({
  baseUrl: `${API_URL}stream_diversions/`,
});
export const SalesforceAccountFactory = new CRUDFactory({
  baseUrl: `${API_URL}salesforce_accounts/`,
});
export const SalesforceOpportunityFactory = new CRUDFactory({
  baseUrl: `${API_URL}salesforce_opportunities/`,
});
export const SnapshotBundleFactory = new CRUDFactory({
  baseUrl: `${API_URL}snapshot_bundles/`,
});
export const HaulerAccountFactory = new CRUDFactory({
  baseUrl: `${API_URL}hauler_accounts/`,
});
export const HaulerLiquidationFactory = new CRUDFactory({
  baseUrl: `${API_URL}hauler_liquidations`,
});
export const CartTypeFactory = new CRUDFactory({
  baseUrl: `${API_URL}cart_types/`,
});
export const BillChargeRepositoryFactory = new CRUDFactory({
  baseUrl: `${API_URL}bill_charge_repositories/`,
});
export const InstructionFactory = new CRUDFactory({
  baseUrl: `${API_URL}instructions/`,
});
export const LoaAgreementCRUDFactory = new CRUDFactory({
  baseUrl: `${API_URL}loa_agreements/`,
});
export const TaxExemptionFactory = new CRUDFactory({
  baseUrl: `${API_URL}tax_exemptions/`,
});
