import CRUDFactory, { API_URL } from './crud_factory';

type Location = {
  id: number;
  tags: { tag: string }[];
  closerId: number;
  targetIntegrationDate: string;
  createdAt: string;
  name: string;
  placemarks: { id: number; name: string }[];
  invoiceBlocker: boolean;
};
export type GetLocationsResponse = Location[];
interface LocationFactoryType extends CRUDFactory {
  get(params?: {}): Promise<GetLocationsResponse>;
}
export default new CRUDFactory({
  baseUrl: `${API_URL}locations/`,
}) as LocationFactoryType;
