import { TableIntrospect } from '@local_types';
import { getFieldType } from './graphql_helpers';
/**
 * @description Check if the input is a function
 * @param {func} functionToCheck
 * @author Han Lai
 */
export const isFunction = (
  functionToCheck: any
): functionToCheck is (...args: any[]) => any => {
  if (!functionToCheck) return false;
  return {}.toString.call(functionToCheck) === '[object Function]';
};

/**
 * @description Check if a given field object is a ForeignKey.
 * @requires GraphQL
 * @param {Object} field must be either a string of the field name,
 * or the field object fetched from graphQl Schema
 */
export const isForeignKey = (
  field: TableIntrospect['__type']['fields'][number] | string
): boolean => {
  if (typeof field === 'string') {
    // just see if it ends with an Id
    return field.endsWith('Id');
  }
  // Then it must be an object
  const type = getFieldType(field);
  const { kind } = type;
  const { name } = field;
  return kind === 'SCALAR' && name.endsWith('Id');
};

/**
 * @description Type Guard for typescript to check if a value is not null. This has predicare
 * so that after this guard function, the value will be a not null value, so typescript will not
 * throw the "xxx could be null" value when you try to access child attributes
 * @param value
 * @author Han Lai
 */

export function notNull<T>(value: T | null | undefined): value is T {
  return !!value;
}
