export const staticImageUrl = (filename: string) => `/assets/${filename}`;
/**
 * @description Generate the map url given a location. Used to generate
 * link that directs to the map page in coyote.
 * @param {*} locationId
 */
export const mapUrl = (locationId: number | string) =>
  `/map?locationID=${locationId}`;

export const isValidUrl = (
  urlString: string,
  supportedProtocols?: string[]
) => {
  try {
    const url = new URL(urlString);
    const checkProtocol = !!supportedProtocols?.length;
    // url.protocol includes the colon at the end, so we need to remove it
    const urlProtocol = url.protocol.slice(0, -1);
    return (
      !checkProtocol || supportedProtocols.includes(urlProtocol.toLowerCase())
    );
  } catch (e) {
    return false;
  }
};
