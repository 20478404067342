import changeCase from 'change-case';
import { toModelName } from '../../utils';

export default (err: Error) => {
  const { message } = err;
  if (!message) return null;
  if (!message.startsWith('PG::')) return null;
  if (message.includes('PG::ForeignKeyViolation:')) {
    // FK violation, use key word (on table) to find the two tables
    // table been referenced, find two double quotes
    let keyPos = message.indexOf('on table');
    let quotePos1 = message.indexOf('"', keyPos);
    let quotePos2 = message.indexOf('"', quotePos1 + 1);
    const table1 = message.substring(quotePos1 + 1, quotePos2);
    keyPos = message.lastIndexOf('on table');
    quotePos1 = message.indexOf('"', keyPos);
    quotePos2 = message.indexOf('"', quotePos1 + 1);
    const table2 = message.substring(quotePos1 + 1, quotePos2);
    return `Cannot update or delete ${toModelName(table1)}, 
    because it still has a ${toModelName(table2)}`;
  }
  if (message.includes('PG::NotNullViolation:')) {
    const keyPos = message.indexOf('in column');
    const quotePos1 = message.indexOf('"', keyPos);
    const quotePos2 = message.indexOf('"', quotePos1 + 1);
    const columnName = message.substring(quotePos1 + 1, quotePos2);
    return `${changeCase.titleCase(columnName)} field cannot be empty!`;
  }
  return null;
};
