/**
 * Converts a camelCase string into a kebab-case version by separating the tokens on the uppercase letters and then
 * joining their lowercase versions with spaces.
 * <p>
 *   <b>Note:</b> that this is a very crude implementation to convert property names to camel case and hence isn't very
 *   optimized to handle repeating numbers. e.g. <tt>cameCase12</tt> will result into <tt>came-case-12</tt>.
 * </p>
 * @param str The string to convert
 * @return the converted value
 */
const camelCaseToKebabCase = (str: string): string =>
  str
    ?.replace(/([A-Z|\d*])/g, ' $1')
    .trim()
    .split(' ')
    .join('-')
    .toLowerCase();

/*
 * A function that takes a string and converts it into sentence casing
 * for example a string like "Hello EveryBody" will be converted to "Hello everybody"
 * works for one sentence only.
 */
const stringToSentenceCase = (str: string): string =>
  typeof str === 'string'
    ? (() => {
        const indexOfFirstLetter = str.search(/[a-zA-Z]/);
        if (indexOfFirstLetter === -1) return str;
        if (indexOfFirstLetter === 0)
          return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        return `${str.slice(0, indexOfFirstLetter - 1)}${str
          .charAt(indexOfFirstLetter)
          .toUpperCase()}${str.slice(indexOfFirstLetter + 1).toLowerCase()}`;
      })()
    : undefined;

/**
 * Trims the supplied string and returns the trimmed value. If the trimmed value is empty, <tt>null</tt> or
 * <tt>undefined</tt> stored as a string, the returned value is <tt>null</tt> literal.
 * @param str the string to process
 */
const trimToNull = (str: any): string => {
  const trimmed = str?.toString().trim();
  return trimmed && trimmed !== 'undefined' && trimmed !== 'null'
    ? trimmed
    : null;
};

/**
 * Trims the supplied string and returns the trimmed value. If the trimmed value is empty, <tt>null</tt> or
 * <tt>undefined</tt> stored as a string, the returned value is an empty string.
 * @param str the string to process
 */
const trimToEmpty = (str: any): string => {
  const trimmedToNull = trimToNull(str);
  return trimmedToNull === null ? '' : trimmedToNull;
};

/**
 * Trims individual values in the supplied comma separated string and returns a new comma separated values string.
 * If a trimmed value in the comma separated values string is empty, <tt>null</tt> or <tt>undefined</tt> stored as a
 * string, it will be dropped from the resulting comma separated string.
 * @param str the comma separated values string to process
 */
const trimCommaSeparatedValues = (str: any): string => {
  return commaSeparatedValuesAsList(str).join(',');
};

/**
 * Joined the given string array with commas to form a string except that the last two elements are separated by the
 * given separator. e.g.
 * <tt>joinAllWithCommaButLastWithSeparator([a, b, c, d], 'or')</tt> returns a, b, c or d<tt></tt>.
 * @param arr The string array to be joined as a string
 * @param lastElementSeparator The separator to be used to join the last two elements. A space is added before and this separator
 * @return {string} the joined value.
 */
const joinAllWithCommaButLastWithSeparator = (
  arr: string[],
  lastElementSeparator: string
) => {
  let joined = null;
  if (arr && arr.length) {
    joined =
      arr.length === 1
        ? arr[0]
        : `${arr.slice(0, -1).join(', ')} ${lastElementSeparator} ${arr.slice(
            -1
          )}`;
  }
  return joined;
};

const commaSeparatedValuesAsList = (str: string): string[] =>
  trimToEmpty(str)
    .split(',')
    .map((token) => trimToNull(token))
    .filter((token) => token !== null);

/**
 * Compares two string values and returns the result of <tt>str1.localCompare(str2)</tt>.
 *
 * @param str1 The reference string
 * @param str2 The string to compare the reference string with
 * @return The result of <tt>str1.localCompare(str2)</tt>, all <tt>null</tt> or <tt>undefined</tt> reference values
 * are considered smaller than non <tt>null</tt> or <tt>undefined</tt> values. Whereas a <tt>null</tt> reference value
 * comparison to <tt>null</tt> results into 0. Same applies for <tt>undefined</tt> values.
 */
const compareStringsAlphabetically = (str1: string, str2: string) => {
  let compResult: number;

  if (!str1) {
    compResult = str2 ? -1 : 0;
  } else if (!str2) {
    compResult = str1 ? 1 : 0;
  } else {
    compResult = str1.localeCompare(str2);
  }
  return compResult;
};

const parseCsvString = (value: string, maxlen?: number) => {
  return value.split(/[ ,]+/).filter((f) => {
    const trimed = f.trim();
    if (maxlen && trimed.length > maxlen) return false;
    return trimed !== '';
  });
};

export {
  camelCaseToKebabCase,
  commaSeparatedValuesAsList,
  compareStringsAlphabetically,
  joinAllWithCommaButLastWithSeparator,
  parseCsvString,
  trimCommaSeparatedValues,
  trimToEmpty,
  trimToNull,
  stringToSentenceCase,
};
