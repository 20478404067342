import { fetchHandler, defaultHeader } from '../utils';
import CRUDFactory from './crud_factory';

type LoaAgreement = {
  id: number;
};
type CreateLoaAgreementResponse = Promise<LoaAgreement>;
class LoaAgreementFactory extends CRUDFactory {
  create(object: {}) {
    const submitData =
      object instanceof FormData ? object : JSON.stringify(object);
    const header = defaultHeader();
    delete header['Content-Type'];
    return fetch(`${this.baseUrl}`, {
      credentials: 'same-origin',
      headers: header,
      method: 'POST',
      body: submitData,
    }).then((result) => fetchHandler(result, { notify: true })) as CreateLoaAgreementResponse;
  }
}
export default new LoaAgreementFactory({ baseUrl: '/loa_agreements/' });
