import * as React from 'react';
import { notification, Spin } from 'antd';

const { useReducer } = React;

export interface LoadingStatusesProps {
  [propName: string]: boolean;
}
export type LoadingStatusesFuncProp = (prop: {
  key: string;
  message: string;
  loading: boolean;
}) => any;
export interface LoadingContextValueProps {
  loadingStatuses: LoadingStatusesProps;
  setLoadingStatus: LoadingStatusesFuncProp;
}
export type LoadingContextType = React.Context<LoadingContextValueProps> & {
  LoadingProvider?: React.FC<{ children: React.ReactNode }>;
};

const LoadingContext: LoadingContextType =
  React.createContext<LoadingContextValueProps>({
    loadingStatuses: {},
    setLoadingStatus: () => {},
  });
export type LoadingContextAction = {
  type: string;
  payload: { key: string; message: string; loading: boolean };
};

const reducer = (state: LoadingStatusesProps, action: LoadingContextAction) => {
  const { key, message, loading } = action.payload;
  const currentState = state[key];
  switch (action.type) {
    case 'SET_LOADING':
      if (currentState !== loading) {
        notification.open({
          key,
          message,
          placement: 'topLeft',
          description: !loading ? 'Done' : <Spin />,
          duration: !loading ? 1 : 0,
        });
      } else {
        return state;
      }
      break;
    default:
  }
  return { ...state, [key]: loading };
};
const LoadingProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [loadingStatuses, dispatch] = useReducer(reducer, {});
  const setLoadingStatus: LoadingStatusesFuncProp = ({
    key,
    message,
    loading,
  }) => {
    // A tradition is to have a type in reducer action, but in this case we are only using one type.
    const type = 'SET_LOADING';
    dispatch({ type, payload: { key, message, loading } });
  };
  return (
    <LoadingContext.Provider value={{ loadingStatuses, setLoadingStatus }}>
      {props.children}
    </LoadingContext.Provider>
  );
};

LoadingContext.LoadingProvider = LoadingProvider;
export default LoadingContext;
